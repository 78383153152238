export const initialState = {
  notifications: []
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case 'NOTIFICATIONS_FIND': {
      if (action.data) {
        const notifications = action.data.notifications;

        return {
          ...state,
          notifications: notifications
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
