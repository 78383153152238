import React from 'react';
import { Row } from 'antd';

import Notify from 'src/components/meta/Notification';

class Dashboard extends React.Component {
  static defaultProps = {
    error: null,
    member: {}
  };
  handleSubmit = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (typeof values.remember === 'undefined') {
          values.remember = false;
        }
        this.signIn(values);
      }
    });
  };

  signIn = values => {
    this.setState({ loading: true });
    this.props
      .signIn(values, this.props.currentLocale)
      .then(() => {
        this.setState({ loading: false });
        this.props.push('/dashboard');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', window.i18n.translate('Notify3'), e);
      });
  };

  toggleShowPass = () => {
    this.state.passShow === 'password'
      ? this.setState({ passShow: 'text' })
      : this.setState({ passShow: 'password' });
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      passShow: 'password'
    };
  }

  handleLocaleSwitch = locale => {
    this.setState({ loading: true });
    this.props
      .changeLocale(locale)
      .then(() => {
        this.props.form.resetFields();
        this.setState({ loading: false });
        // location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    return <Row type="flex" justify="space-around" align="middle" />;
  }
}

export default Dashboard;
