import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findDealDoneStock,
  findAuctionWonStock,
  findAuctionLostStock,
  setStockDetailsId
} from 'src/redux/actions/stock';
import { findBidData } from 'src/redux/actions/bidding';
import {
  getLocale,
  getDealDoneStock,
  getAuctionWonStock,
  getAuctionLostStock,
  getUser,
  getBidData
} from 'src/redux/selectors';

class History extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      dealDoneStock,
      wonStock,
      lostStock,
      user,
      findDealDoneStock,
      findAuctionWonStock,
      findAuctionLostStock,
      setStockDetailsId,
      findBidData,
      bidData,
      push
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        dealDoneStock={dealDoneStock}
        wonStock={wonStock}
        lostStock={lostStock}
        user={user}
        findDealDoneStock={findDealDoneStock}
        findAuctionWonStock={findAuctionWonStock}
        findAuctionLostStock={findAuctionLostStock}
        setStockDetailsId={setStockDetailsId}
        findBidData={findBidData}
        bidData={bidData}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  dealDoneStock: getDealDoneStock(state) || [],
  wonStock: getAuctionWonStock(state) || [],
  lostStock: getAuctionLostStock(state) || [],
  bidData: getBidData(state) || {},
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  findDealDoneStock,
  findAuctionWonStock,
  findAuctionLostStock,
  setStockDetailsId,
  findBidData,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(History);
