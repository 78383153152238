export const initialState = {
  currentRequests: [],
  previousRequests: [],
  myRequest: [],
  basketRequestIds: [],
  auctionRequest: [],
  auctionRequestIds: [],
  dealDoneRequest: [],
  wonRequest: [],
  lostRequest: [],
  editRequest: undefined,
  requestDetails: undefined,
  requestDetailsId: '',
  customTime: undefined,
  timeStamp: undefined
};

export default function requestReducer(state = initialState, action) {
  switch (action.type) {
    case 'PEXREQUEST_FIND_ALLPEXREQUESTS': {
      if (action.data) {
        const currentRequests = action.data.currentRequests;
        const previousRequests = action.data.previousRequests;

        return {
          ...state,
          currentRequests: currentRequests,
          previousRequests: previousRequests
        };
      }
      return initialState;
    }
    case 'PEXREQUEST_FIND_MYPEXREQUESTS': {
      if (action.data) {
        const myRequest = action.data.myRequest;

        return {
          ...state,
          myRequest: myRequest
        };
      }
      return initialState;
    }
    case 'PEXREQUEST_FIND_VEHICLEDETAILS': {
      if (action.data) {
        const requestDetails = action.data.requestDetails;

        return {
          ...state,
          requestDetails: requestDetails
        };
      }
      return initialState;
    }
    case 'PEXREQUEST_FIND_AUCTIONPEXREQUEST': {
      if (action.data) {
        const auctionRequest = action.data.auctionRequest;
        let auctionRequestIds = [];
        auctionRequest.forEach(request => {
          auctionRequestIds.push(request._id);
        });
        return {
          ...state,
          auctionRequest: auctionRequest,
          auctionRequestIds: auctionRequestIds
        };
      }
      return initialState;
    }
    case 'PEXREQUEST_FIND_DEALDONEPEXREQUEST': {
      if (action.data) {
        const dealDoneRequest = action.data.dealDoneRequest;
        return {
          ...state,
          dealDoneRequest: dealDoneRequest
        };
      }
      return initialState;
    }
    case 'PEXREQUEST_FIND_WONPEXREQUEST': {
      if (action.data) {
        const wonRequest = action.data.wonRequest;
        return {
          ...state,
          wonRequest: wonRequest
        };
      }
      return initialState;
    }
    case 'PEXREQUEST_FIND_LOSTPEXREQUEST': {
      if (action.data) {
        const lostRequest = action.data.lostRequest;
        return {
          ...state,
          lostRequest: lostRequest
        };
      }
      return initialState;
    }
    case 'PEXREQUEST_SET_EDITVEHICLE': {
      if (action.data) {
        const editRequest = action.data;

        return {
          ...state,
          editRequest: editRequest
        };
      }
      return initialState;
    }
    case 'PEXREQUEST_SET_VEHICLEDETAILSID': {
      if (action.data) {
        const id = action.data;
        return {
          ...state,
          requestDetailsId: id
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_LIST': {
      if (action.data) {
        let auctionRequest = state.auctionRequest;
        let updatedRequest = action.data.request;
        auctionRequest.map(obj => {
          obj.highestBid = updatedRequest.find(
            o => o._id === obj._id
          ).highestBid;
          obj.addAuctionMinutes = updatedRequest.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedRequest.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          auctionRequest: auctionRequest,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_BASKET': {
      if (action.data) {
        let basketRequest = state.basketRequest;
        let updatedRequest = action.data.request;
        basketRequest.map(obj => {
          obj.highestBid = updatedRequest.find(
            o => o._id === obj._id
          ).highestBid;
          obj.addAuctionMinutes = updatedRequest.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedRequest.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          basketRequest: basketRequest,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_SINGLE': {
      if (action.data) {
        let requestDetails = state.requestDetails || {};
        requestDetails.highestBid = action.data.request.highestBid;
        requestDetails.addAuctionMinutes =
          action.data.request.addAuctionMinutes;
        const timeStamp = action.data.timeStamp;

        return {
          ...state,
          requestDetails: requestDetails,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
