export default {
  HamMenu1: 'Tuisblad',
  HamMenu2: 'Oor Ons',
  HamMenu3: 'Kenmerke',
  HamMenu4: 'Betalingopsies',
  HamMenu5: 'Kontak Ons',
  HamMenu6: 'FAQ',
  HamMenu7: 'Dienste',
  HomeTitle: 'Tuisblad',
  HomeText1a: 'Jou toekoms',
  HomeText1b: 'begin hier',
  HomeText2:
    'Maklik-toeganklike gehalte-leermateriaal vir al graad 4 tot 6 vakke (Afrikaans, Engels, Lewensvaardighede, Natuurwetenskappe en Tegnologie, Sosiale Wetenskappe en Wiskunde). Leer enige plek, enige tyd! Beskikbaar in Afrikaans en Engels.',
  HomeBegin: 'Sien Demo',
  HomeFreeLesson: 'Gratis Les',
  HomeRegister: 'Registreer',
  Signup: 'Registreer',
  Signin: 'Teken in',
  Signout: 'Teken uit',
  Accept: 'Aanvaar',
  Update: 'Aanvaar',
  Next: 'Volgende',
  Cancel: 'Kanselleer',
  Continue: 'Gaan Voort',
  TryAgain: 'Probeer Weer',
  CloseVideo: 'Maak Video Toe',
  AboutTitle: 'Oor Ons',
  AboutText1:
    "Ons is 'n Suid-Afrikaanse maatskappy wat 'n nismark verken om verpersoonlike leer te verskaf.",
  AboutText2:
    "Dit kan benut word deur aanlyn mobiele toestelle, skootrekenaars en tablette en ook vir aflyn gebruik op 'n slimtoestel afgelaai kan word.",
  AboutText3:
    'Daar word aanvanklik voorsiening gemaak vir vakke vir graad 4 tot graad 6 in Afrikaans en in Engels, met langtermyn-uitbreidingsplanne om ander vakke, grade en tale in te sluit.',
  AboutText4:
    "Ons uiters vaardige span het 'n lewendige en kreatiewe visie om leerders teen hulle eie tempo te help.",
  FeaturesTitle: 'Kenmerke',
  Offline: 'Aflyn',
  OfflineText1:
    'Aanlyn se toekoms is aflyn.  Lees al jou inhoud op jou slimtoestel terwyl jy aflyn is.',
  'Track&Report': 'Volg en doen verslag',
  'Track&ReportText1':
    'Daar word tred gehou met alle lesse en dit toon jou vordering vir elke les.',
  MobileLessons: 'Mobiele lesse',
  MobileLessonsText:
    'Leer enige plek, enige tyd.  Beskikbaar op selfoon, tablet en rekenaar.',
  InteractiveLessons: 'Interaktiewe lesse',
  InteractiveLessonsText:
    "Ons lesse is baie interaktief, met video's, animasie, infografika, voorlesings en soveel meer.",
  RewardSystem: 'Beloningstelsel',
  RewardSystemText:
    'Ontvang na elke les toekennings om met die Infonerd-leer op te beweeg.',
  FlexibleSessions: 'Aanpasbare sessies',
  FlexibleSessionsText:
    'Nie almal leer teen dieselfde tempo nie. Herhaal lesse soveel keer as wat jy wil.',
  PricingTitle: 'Betalingsopsies',
  PricingText: "Kies 'n plan wat by jou behoeftes pas.",
  MonthlyTitle: 'Maandeliks',
  AnnuallyTitle: 'Jaarliks',
  MonthlyText1: 'Elektroniese toeganklikheid tot al jou graad se vakke',
  MonthlyText2: 'Enige-tyd-toegang tot jou lesse',
  MonthlyText3: 'Ontwikkelingsverslag',
  MonthlyText4: "Video's",
  MonthlyText5: 'Klankboek beskikbaar op alle lesse',
  MonthlyText6: "'n PDF-opsomming van alle lesse",
  MonthlyText7: 'Assessering na elke les',
  MonthlyText8: 'Oefenvraestelle',
  MonthlyText9: 'Afrikaanse en Engelse Klastoesprake',
  MonthlySelect: 'Kies',
  MonthlyText: 'R199 R99 Black Friday',
  AnnuallyText: 'R1999 R999 Black Friday',
  BlackFridayTitle1: 'Black Friday 50% afslag!',
  BlackFridayTitle2: 'Maandeliks',
  BlackFridayTitle3: 'Jaarliks',
  BlackFridayText1:
    "As jy vandag registreer, betaal jy eenmalige registrasiegeld van R99 en dan 'n maandeliks-herhalende debietorder van R99 vanaf Januarie 2019.",
  BlackFridayText2:
    "As jy vandag registreer, betaal jy 'n eenmalige jaarlikse tarief van R999 vir die hele 2019.",
  ContactTitle: 'Kontak Ons',
  ContactRegister: 'Registreer',
  ContactSignup: 'Registreer',
  ContactText0: 'Eenheid 24',
  ContactText1: 'Oakhurst Kantoorpark',
  ContactText2: 'Grippenweg 1',
  ContactText3: 'Boksburg',
  ContactText4: 'Bartlett',
  ContactText5: 'Jou naam',
  ContactText6: 'Jou e-posadres',
  ContactText7: 'Jou telefoonnommer',
  ContactText8: 'Tik jou boodskap hier in',
  ContactText9: 'Vul in jou naam!',
  ContactText10: 'Vul in jou e-posadres!',
  ContactText11: 'Vul in jou foon nommer!',
  ContactText12: 'Vul in jou boodskap!',
  ContactText13: 'Naam...',
  ContactText14: 'E-posadres...',
  ContactText15: 'Foon nommer...',
  ContactText16: 'Boodskap...',
  NewsletterText1: 'Registreer',
  ContactSend: 'Stuur',
  Subscribe: 'Registreer vir ons nuusbrief',
  TCText: 'Terme en Voorwaardes',
  FAQHeading: 'Algemene Vrae',
  FAQText: 'As jy vrae het, vind die antwoorde hier.',
  FAQPlaceholder: 'Deursoek Vrae',
  SignUpInput1: 'Naam',
  SignUpInput2: 'Van',
  SignUpInput3: 'E-posadres',
  SignUpInput4: 'Wagwoord',
  SignUpValEr1: 'Vul in jou naam en van!',
  SignUpValEr2: 'Vul in jou e-posadres!',
  SignUpValEr3: 'Vul in jou wagwoord!',
  SignUpValEr7: 'Kies een!',
  SignInInput1: 'E-posadres',
  SignInInput2: 'Wagwoord',
  SignInInput3: 'Onthou my',
  SignInInput4: "Het nie 'n rekening nie?",
  SignInValEr1: 'Vul in jou e-posadres!',
  SignInValEr2: 'Vul in jou wagwoord!',
  SidebarText1: 'My Paneelbord',
  SidebarText2: 'My Voertuie',
  SidebarText3: 'Voeg Voertuig',
  SidebarText4: 'Rekening Instellings',
  SidebarEr1: 'Geen profiel gekies',
  SidebarEr2:
    "'n Profiel moet eers gekies word voor die paneelbord gesien kan word.",
  ProfilesListText1: 'Skep Profiel',
  ProfilesAddInput1: 'Volle Name en Van',
  ProfilesAddInput2: 'Skool',
  ProfilesAddInput3: 'Geslag',
  ProfilesAddInput3a: 'Manlik',
  ProfilesAddInput3b: 'Vroulik',
  ProfilesAddInput4: 'Ouderdom',
  ProfilesAddInput5: 'Graad',
  ProfilesAddInput5a: 'Graad 4',
  ProfilesAddInput5b: 'Graad 5',
  ProfilesAddInput5c: 'Graad 6',
  ProfilesAddValEr1: 'Vul in jou naam en van!',
  ProfilesAddValEr2: 'Vul in jou skool!',
  ProfilesAddValEr3: 'Kies jou geslag!',
  ProfilesAddValEr4: 'Vul in jou ouderdom!',
  ProfilesAddValEr5: 'Kies jou graad!',
  ProfilesAddValEr6: "Naam en van is 'n vereiste!",
  ProfilesAddValEr7: "Skool is 'n vereiste!",
  ProfilesAddValEr8: "Geslag is 'n vereiste!",
  ProfilesAddValEr9: "Ouderdom is 'n vereiste!",
  ProfilesAddValEr10: "Graad is 'n vereiste!",
  AccountText1: 'My Rekening',
  AccountText2: 'Subskripsie',
  AccountText3: 'Rekening Besonderhede',
  SubscriptionText1: 'Graad',
  SubscriptionText2: 'Datum Aangesluit',
  SubscriptionText3: 'Betaling Metode',
  SubscriptionText4: 'Verander',
  SubscriptionText5: 'Sien Verlede Betalings',
  SubscriptionText6: 'Geen Subskripsie',
  SubscriptionText7: 'Geen',
  SubscriptionText8: 'Opgeskort',
  SubscriptionText9: 'Registreer',
  SubscriptionText10: 'Deregistreer',
  SubscriptionText11: 'Maandeliks',
  SubscriptionText12: 'Maand',
  SubscriptionText13: 'Dae oor',
  SubscriptionText14: 'Aktief',
  SubscriptionText15: 'Verval',
  SubscriptionText16: 'Jaarliks',
  SubscriptionText17: 'Kanselleer',
  SubscriptionText18: 'Herregistreer',
  PaymentText1: 'Maand',
  PaymentText2: 'Maandeliks',
  PaymentText3: 'Graad',
  PaymentText4: 'Periode',
  PaymentText5: 'Verander Betaling Metode',
  PaymentText6: 'Jaarliks',
  PaymentText7: 'Maak Betaling',
  SummaryText1: 'Subskripsie Opsomming',
  SummaryText2: 'Naam',
  SummaryText3: 'Graad',
  SummaryText4: 'Betaling Metode',
  SummaryText5: 'Periode',
  SummaryText6: 'Bedrag',
  CompletedText1: 'Betaling Suksesvol',
  CompletedText2: 'Betaling Gekanselleer',
  CompletedText3: "'n Kwotansie sal gestuur word na jou e-posadres.",
  CompletedText4: 'Probeer weer.',
  PaymentHeading: 'Betaling',
  Selection: 'Seleksie',
  Summary: 'Opsomming',
  Confirmation: 'Bevestiging',
  HistoryText1: 'Vorige Betalings',
  HistoryText2: 'Id',
  HistoryText3: 'Status',
  HistoryText4: 'Bedrag',
  HistoryText5: 'Tiepe',
  HistoryText6: 'Datum',
  FeatureLockedText1: 'Funksie Gesluit',
  FeatureLockedText2: 'Hierdie funksie sal in 2019 vrygestel word.',
  HeaderText1: 'Profiel Instellings',
  HeaderText2: 'Rekening Instellings',
  HeaderText3: 'Soek lesse',
  Notify1: 'Hierdie funksie sal geaktiveer word in 2019',
  Notify2: 'Suksesvolle afteken ',
  Notify3: 'Teken in Fout',
  Notify4: 'Boodskap gestuur.',
  Notify5: 'Boodskap nie gestuur.',
  Notify6: 'Suksesvolle registrasie',
  Notify7: 'Registrasie Fout',
  Notify8: 'Les Fout',
  Notify9: 'Suksesvolle skep profiel',
  Notify10: 'Profiel Fout',
  Notify11: 'Sukses',
  Notify12: 'Aflaai suksesvol',
  Notify13: 'Verwyder aflaai suksesvol',
  ProfilesEditText1: 'Redigeer Profiel',
  ProfilesEditText2: 'Aanvaar',
  ProfileCardText1: 'Kies profiel vir betaling opsies',
  ServicesTitle: 'Dienste',
  ServicesHead1: 'Vraestelle',
  ServicesHead2: 'Toesprake',
  ServicesHead3: 'Opsommings',
  ServicesHead4: 'Oefeninge',
  ServicesHead5: 'Redenaars',
  ServicesText1:
    "Vraestelle kan 'n belangrike rol in u kind se voorbereiding vir 'n toets of eksamen speel.",
  ServicesText2:
    'Toesprake word geskryf volgens die ouer se vereistes, onderwerp, tydsduur en graad van die kind.',
  ServicesText3:
    'Al ons lesse word opgesom deur die gebruik van verskeie KABV-gebaseerde handboeke, om leerwerk makliker te maak. Ons opsommings is beskikbaar op selfoon, tablet en rekenaar.',
  ServicesText4:
    "Ons lesoefeninge is 'n baie goeie hulpmiddel vir die voorbereiding van eksamens en toetse.",
  ServicesText5:
    'Redenaars praatjies is nie ingesluit in die volle pakket nie, maar kan afsonderlik bestel word. Doen navraag by ons vriendelike personeel oor verdere inligting op hierdie diens.',
  ServicesHead6: 'Taak Inligting',
  ServicesText6:
    'Taak Inligting word bymekaar gemaak volgens die instruksie wat ontvang word. Die voltooiing van die taak is steeds die leerling se verantwoordelikheid.',
  DashboardGrade: 'Graad',
  DashboardOverview: 'Oorsig',
  DashboardCompLes: 'Voltooide Lesse',
  DashboardGold: 'Goue Trofees',
  DashboardSilver: 'Silwer Trofees',
  DashboardBronze: 'Brons Trofees',
  DashboardBadges: 'Balkies',
  DashboardSubjects: 'Vakke',
  DashboardStart: 'Begin',
  DashboardCont: 'Gaan aan',
  DashboardQuizScore: 'Algehele toetstelling',
  DashboardPassed: 'Geslaag',
  DashboardLesson: 'Les',
  DashboardTerms: 'Kwartaal',
  LessonsDownloaded: 'Afgelaai',
  LessonsNotDownloaded: 'Laai af',
  OfflineMessage:
    'Aflyn - Net afgelaaide lesse sal wys en vordering sal gesinkroniseer word sodra jy aanlyn is',
  OfflineMessage2:
    'Aflyn - Jou vordering sal gesinkroniseer word sodra jy aanlyn is',
  DownloadMessageHeading: 'Lesse Aflaai',
  DownloadMessage:
    'Let op dat lesse vanaf 5MB tot 40MB groot kan wees elk, data kostes mag van toepassing wees. Hierdie informasie gaan net een keer wys as jy dit aanvaar.',
  ForgotPassword: 'Wagwoord vergeet?',
  ForgotPasswordReset: 'Herstel',
  ResetPassword: 'Herstel Wagwoord',
  PassResetSend:
    "'n Wagwoord herstel e-pos was gestuur na die gerigestreerde e-posadres.",
  PassResetSuccess:
    'Wagwoord is verander, gebruik die nuwe wagwoord om in te teken.',
  New: 'Nuwe',
  UpdateProfile: 'Opdateer suksesvol.',
  ImageSmaller: 'Foto moet kleiner as 4MB wees.',
  PinWrong: "Verskaf asseblief 'n geldige pin",
  PinError: 'Pin Fout',
  NoPin: "Geen pin gevind, verskaf 'n pin. 4 - 6 karakters",
  EnterPin: 'Verskaf u pin om voort te gaan',
  NewPin: "Verskaf 'n nuwe pin. 4 - 6 karakters",
  NewPinDone: 'Nuwe pin gestel',
  ForgotPin: 'Pin vergeet?',
  ResetPin: 'Herstel',
  Report: 'Verslag',
  ReportHeading: 'Verslag',
  ReportSelectProfile: 'Kies Profiel',
  ReportSelectSubject: 'Kies Vak',
  ReportAllSubjects: 'Alle Vakke',
  ReportAvgQuiz: 'Algehele Toetstelling: ',
  ReportLesComplete: 'Lesse Voltooi: ',
  ReportTerm1: 'Kwartaal 1',
  ReportTerm2: 'Kwartaal 2',
  ReportTerm3: 'Kwartaal 3',
  ReportTerm4: 'Kwartaal 4',
  ReportOutOf: 'van',
  ReportChildName: 'Kind se Naam',
  ReportGrade: 'Graad',
  ReportSubject: 'Vak',
  Print: 'Druk',
  BackAgain: 'Druk weer terug om toe te maak',
  Friend: 'Vriend/Vriendin',
  Pamphlet: 'Pamflet',
  Radio: 'Radio',
  LeaderboardHeading: 'Voorloper leer',
  LeaderboardSelectGrade: 'Kies graad',
  DeleteProfile: 'Verwyder Profiel',
  DeleteProfileConfirm: 'Is u seker u wil die profiel verwyder?',
  DeleteProfileDone: 'Profiel is verwyder',
  DeleteProfileError: 'Profiel verwyder fout',
  NotificationHeading: 'Kennisgewings',
  Yes: 'Ja',
  No: 'Nee',
  DashboardRank: 'Voorloper leer Posisie',
  Done: 'Klaar!',
  CloseWindow: 'Jy kan maar hierdie webblaaier toe maak.',
  RemoveDownloadMessageHeading: 'Verwyder Afgelaaide Les',
  RemoveDownloadMessage: 'Is jy seker jy wil die afgelaaide les verwyder?',
  Request: 'Aanvraag',
  Afrikaans: 'Afrikaans',
  English: 'Engels',
  SpeechRequestTipa: 'NB! ',
  SpeechRequestTipb:
    "Daar is geen kostes verbonde aan klastoesprake as u kind 'n aktiewe Infonerd profiel het nie.",
  SpeechRequestTipc:
    'Daar is kostes verbonde aan redenaars toesprake.  Doen asseblief navraag oor koste.',
  SpeechRequestTipd:
    "Daar is geen kostes verbonde aan taak inligting as u kind 'n aktiewe Infonerd profiel het nie.",
  SpeechRequestText1: 'Naam van kind',
  SpeechRequestText2: 'E-posadress',
  SpeechRequestText3: 'Infonerd profiel',
  SpeechRequestText4: 'Taal',
  SpeechRequestText5: 'Graad',
  SpeechRequestText6: 'Skool',
  SpeechRequestText7: 'Onderwerp',
  SpeechRequestText8: 'Tyds verduur',
  SpeechRequestText9: 'Vereiste datum',
  SpeechRequestText10: 'Instruksie aangeheg',
  SpeechRequestText10a: 'Laai instruksie op',
  SpeechRequestText11: 'Verdere inligting',
  SpeechRequestText12: 'Vak',
  SpeechRequestTextErr1: 'Vul in die kind se naam',
  SpeechRequestTextErr2: 'Vul in jou e-posadres',
  SpeechRequestTextErr3: 'Kies een',
  SpeechRequestTextErr4: 'Vul in die kind se taal',
  SpeechRequestTextErr5: 'Vul in die kind se graad',
  SpeechRequestTextErr6: 'Vul in die kinf se skool',
  SpeechRequestTextErr7: 'Vul in die onderwerp',
  SpeechRequestTextErr8: 'Vul in die tyds verduur',
  SpeechRequestTextErr9: 'Vul in die vereiste datum',
  SpeechRequestTextErr10: 'Heg die instruksie aan',
  SpeechRequestTextErr12: 'Vul in die vak',
  Showcase: 'Voorbeelde',
  ShowcaseFreeLessons: 'Sien Gratis Lesse',
  ShowcaseAudio: 'Sien Klank Lesse',
  ShowcaseSummaries: 'Sien Opsommings',
  ShowcaseOther: 'Sien Ander Voorbeelde',
  Reviews: 'Terugvoering',
  ReviewsHeading: 'Wat mense sê',
  Info: 'Aanvraag',
  ShowcasePar:
    "Kyk gerus na 'n verskeidenheid voorbeelde van Infonerd se werk. Ons is baie trots op alle werk wat ons aanbied. Al ons werk word deur 2 professionele taalkonsultante deurgegaan om spelfoute en sinskonstruksie foute tot 'n minimaal te hou."
};
