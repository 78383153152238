import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { signIn, changePassword } from 'src/redux/actions/user';
import { getLocale, getUser } from 'src/redux/selectors';
import changeLocale from 'src/redux/actions/locale';

class SignIn extends Component {
  render = () => {
    const { Layout, signIn, changePassword, user, push } = this.props;

    return (
      <Layout
        signIn={signIn}
        changePassword={changePassword}
        user={user}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  user: getUser(state)
});

const mapDispatchToProps = {
  signIn,
  changePassword,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
