import React from 'react';
import {
  SearchOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  DeleteFilled
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Card,
  Checkbox
} from 'antd';
import Highlighter from 'react-highlight-words';
import Notify from 'src/components/meta/Notification';
import styled from 'styled-components';
import { regExpLiteral } from '@babel/types';
const { Column, ColumnGroup } = Table;
const FormItem = Form.Item;
const { TextArea } = Input;
const option = Select.option;
const numeral = require('numeral');
const { TabPane } = Tabs;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #8898aa !important;
    background: #f1f1f1 !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
    font-weight: 500;
    color: #525f7f;
    font-size: 15px;
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
class UserFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <Row>
          <Col span={10}>
            <FormItem label={<span>Full Name</span>}>
              {this.props.form.getFieldDecorator('name', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    //pattern: RegExp(
                    //  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                    //),
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.name
                  : ''
              })(<Input disabled />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>Number</span>}>
              {this.props.form.getFieldDecorator('number', {
                rules: [
                  { required: true, message: 'User number required' },
                  {
                    pattern: RegExp(/\d/g),
                    message: 'A valid number is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.number
                  : ''
              })(<Input maxLength={10} />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>Email</span>}>
              {this.props.form.getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'Field entry required'
                  },
                  {
                    type: 'email',
                    message: 'A valid email is required.'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.email
                  : ''
              })(<Input disabled />)}
            </FormItem>
          </Col>
          <Col span={2} />
          {/* <Col span={10}>
            <FormItem label={<span>Country of Birth</span>}>
              {this.props.form.getFieldDecorator('countryOfBirth', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.countryOfBirth
                  : ''
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>ID/Passport Number</span>}>
              {this.props.form.getFieldDecorator('identityNumber', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.identityNumber
                  : ''
              })(<Input />)}
            </FormItem>
          </Col> */}
          {/* <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>ID/Passport Number</span>}>
              {this.props.form.getFieldDecorator('identityNumber', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.identityNumber
                  : ''
              })(<Input />)}
            </FormItem>
          </Col> */}
          {/* <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>Address</span>}>
              {this.props.form.getFieldDecorator('address', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.address
                  : ''
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>Race</span>}>
              {this.props.form.getFieldDecorator('race', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.race
                  : ''
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>Gender</span>}>
              {this.props.form.getFieldDecorator('gender', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.gender
                  : ''
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>Club</span>}>
              {this.props.form.getFieldDecorator('club', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.club
                  : ''
              })(
                <select
                  style={{
                    border: '1px solid lightgrey',
                    height: '2.3em',
                    borderRadius: '0.3em',
                    width: '100%'
                  }}
                >
                  {' '}
                  <option value="" disabled selected>
                    Select option
                  </option>
                  <option value="Figure Skating">Figure Skating</option>
                  <option value="Synchro Club">Synchro Club</option>
                </select>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>Skating School</span>}>
              {this.props.form.getFieldDecorator('school', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.school
                  : ''
              })(
                <select
                  placeholder="Member Type"
                  style={{
                    border: '1px solid lightgrey',
                    height: '2.3em',
                    borderRadius: '0.3em',
                    width: '100%'
                  }}
                >
                  {' '}
                  <option value="" disabled selected>
                    Select option
                  </option>
                  <option value="Evolution on Ice">Evolution on Ice</option>
                  <option value="Flying Blades">Flying Blades</option>
                  <option value="SA School of Skating">
                    SA School of Skating
                  </option>
                </select>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>Head Coach</span>}>
              {this.props.form.getFieldDecorator('coach', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.coach
                  : ''
              })(
                <select
                  placeholder="Member Type"
                  style={{
                    border: '1px solid lightgrey',
                    height: '2.3em',
                    borderRadius: '0.3em',
                    width: '100%'
                  }}
                >
                  {' '}
                  <option value="" disabled selected>
                    Select option
                  </option>
                  <option value="Alicia Gerber">Alicia Gerber</option>
                  <option value="Konrad Giering">Konrad Giering</option>
                  <option value="Yolande Giering">Yolande Giering</option>
                  <option value="Lejeanne Hennessey">Lejeanne Hennessey</option>
                  <option value="Justin Pietersen">Justin Pietersen</option>
                  <option value="Susan Marais">Susan Marais</option>
                  <option value="Nadia Snyman">Nadia Snyman</option>
                  <option value="Meredith Potgieter">Meredith Potgieter</option>
                  <option value="Veronique Newman">Veronique Newman</option>
                  <option value="Dantin Broodryk">Dantin Broodryk</option>
                </select>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Member Type</span>}>
              {this.props.form.getFieldDecorator('memberType', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.memberType
                  : ''
              })(
                <select
                  placeholder="Member Type"
                  style={{
                    border: '1px solid lightgrey',
                    height: '2.3em',
                    borderRadius: '0.3em',
                    width: '100%'
                  }}
                >
                  {' '}
                  <option value="" disabled selected>
                    Select option
                  </option>
                  <option value="Non GAN Member">Non GAN Member</option>
                  <option value="Club Member">Club Member</option>
                  <option value="GAN Member">GAN Member</option>
                </select>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>Membership Number</span>}>
              {this.props.form.getFieldDecorator('memberNumber', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.memberNumber
                  : ''
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>Level Passed</span>}>
              {this.props.form.getFieldDecorator('levelPassed', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.levelPassed
                  : ''
              })(<Input />)}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10}>
            <FormItem label={<span>Section</span>}>
              {this.props.form.getFieldDecorator('competition', {
                rules: [
                  { required: true, message: 'User full name required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.competition
                  : ''
              })(
                <select
                  placeholder="Section"
                  style={{
                    border: '1px solid lightgrey',
                    height: '2.3em',
                    borderRadius: '0.3em',
                    width: '100%'
                  }}
                >
                  <option value="" disabled selected>
                    Select option
                  </option>
                  <option value="Chicks Girls">Chicks Girls</option>
                  <option value="Chicks Boys">Chicks Boys</option>
                  <option value="Sparrows Girls">Sparrows Girls</option>
                  <option value="Sparrows Boys">Sparrows Boys</option>
                  <option value="Bronze Hawks Girls">Bronze Hawks Girls</option>
                  <option value="Bronze Hawks Boys">Bronze Hawks Boys</option>
                  <option value="Silver Falcons women">
                    Silver Falcons women
                  </option>
                  <option value="Silver Falcons men">Silver Falcons men</option>
                  <option value="Silver Falcons Club Women">
                    Silver Falcons Club Women
                  </option>
                  <option value="Silver Falcons Club Men">
                    Silver Falcons Club Men
                  </option>
                  <option value="Golden Eagles Women">
                    Golden Eagles Women
                  </option>
                  <option value="Golden Eagles Men">Golden Eagles Men</option>
                  <option value="Golden Eagles Club Women">
                    Golden Eagles Club Women
                  </option>
                  <option value="Golden Eagles Club men">
                    Golden Eagles Club men
                  </option>
                  <option value="Emerald Condor Women">
                    Emerald Condor Women
                  </option>
                  <option value="Emerald Condor Men">Emerald Condor Men</option>
                  <option value="Ruby Condor Women">Ruby Condor Women</option>
                  <option value="Ruby Condor Men">Ruby Condor Men</option>
                  <option value="Inter Bronze Solo Dance">
                    Inter Bronze Solo Dance
                  </option>
                  <option value="Bronze Solo Dance">Bronze Solo Dance</option>
                  <option value="Inter Gold Solo Dance">
                    Inter Gold Solo Dance
                  </option>
                  <option value="Non GAN Member">Non GAN Member</option>
                  <option value="Gold Solo Dance">Gold Solo Dance</option>
                  <option value="Inter Silver Solo Dance">
                    Inter Silver Solo Dance
                  </option>
                  <option value="Silver Solo Dance">Silver Solo Dance</option>
                  <option value="Juvenile Pairs">Juvenile Pairs</option>
                  <option value="Synchro">Synchro</option>
                  <option value="Juvenile Girls">Juvenile Girls</option>
                  <option value="Juvenile Boys">Juvenile Boys</option>
                  <option value="Pre-Novice Girls">Pre-Novice Girls</option>
                  <option value="Pre-Novice Boys">Pre-Novice Boys</option>
                  <option value="Novice Women">Novice Women</option>
                  <option value="Novice Men">Novice Men</option>
                  <option value="Junior Women">Junior Women</option>
                  <option value="Junior Men">Junior Men</option>
                  <option value="Senior Women">Senior Women</option>
                  <option value="Senior Men">Senior Men</option>
                </select>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Division</span>}>
              {this.props.form.getFieldDecorator('division', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.division
                  : ''
              })(
                <select
                  placeholder="Division"
                  style={{
                    border: '1px solid lightgrey',
                    height: '2.3em',
                    borderRadius: '0.3em',
                    width: '100%'
                  }}
                >
                  <option value="" disabled selected>
                    Select option
                  </option>
                  <option value="Division A">Division A</option>
                  <option value="Division B">Division B</option>
                </select>
              )}
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={10} style={{ marginBottom: '-1em' }}>
            <FormItem label={<span>Discipline</span>}>
              {this.props.form.getFieldDecorator('discipline', {
                rules: [
                  { required: true, message: 'Field entry required' },
                  {
                    message: 'A name and surname is required'
                  }
                ],
                initialValue: this.props.editUser
                  ? this.props.editUser.discipline
                  : ''
              })(
                <select
                  placeholder="Discipline"
                  style={{
                    border: '1px solid lightgrey',
                    height: '2.3em',
                    width: '100%',
                    borderRadius: '0.3em'
                  }}
                >
                  <option value="" disabled selected>
                    Select option
                  </option>
                  <option value="Solo Skating">Solo Skating</option>
                  <option value="Solo Ice Dance">Solo Ice Dance</option>
                  <option value="Pairs Skating">Pairs Skating</option>
                  <option value="Synchronised Skating">
                    Synchronised Skating
                  </option>
                </select>
              )}
            </FormItem>
          </Col> */}
        </Row>
        {/*<FormItem label={<span>Brand</span>}>
          {this.props.form.getFieldDecorator("brand", {
            rules: [{ required: false, message: "Brand is required" }],
            initialValue: this.props.editUser ? this.props.editUser.brand : "",
          })(
            <select>
              <option value='megatheron'>Mega Theron</option>
              <option value='kia'>Kia</option>
              <option value='mahindra'>Mahindra</option>
              <option value='chery'>Chery</option>
              <option value='gwm'>GWM</option>
              <option value='haval'>Haval</option>
            </select>
          )}
        </FormItem>
        <FormItem label={<span>Branch</span>}>
          {this.props.form.getFieldDecorator("branch", {
            rules: [
              {
                required: false,
                message: "Branch is required",
              },
            ],
            initialValue: this.props.editUser ? this.props.editUser.branch : "",
          })(
            <select>
              <option value='klerksdorp'>Klerksdorp</option>
              <option value='potchefstroom'>Potchefstroom</option>
              <option value='mahikeng'>Mahikeng</option>
              <option value='lichtenburg'>Lichtenburg</option>
            </select>
          )}
        </FormItem>*/}
        {/*<FormItem label={"Roles Stock"}>
          {this.props.form.getFieldDecorator("isAdd", {
            valuePropName: "checked",
            initialValue: this.props.editUser
              ? this.props.editUser.isAdd
                ? true
                : false
              : false,
          })(<Checkbox>Add Stock</Checkbox>)}
          {this.props.form.getFieldDecorator("isEdit", {
            valuePropName: "checked",
            initialValue: this.props.editUser
              ? this.props.editUser.isEdit
                ? true
                : false
              : false,
          })(<Checkbox>Edit Stock</Checkbox>)}
          {this.props.form.getFieldDecorator("isDelete", {
            valuePropName: "checked",
            initialValue: this.props.editUser
              ? this.props.editUser.isDelete
                ? true
                : false
              : false,
          })(<Checkbox>Delete Stock</Checkbox>)}*/}
        {/*{this.props.form.getFieldDecorator('isBatch', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isBatch
                ? true
                : false
              : false
          })(<Checkbox>Batch Request</Checkbox>)}*/}
        {/*</FormItem>*/}
        {/*<FormItem>
          {this.props.form.getFieldDecorator('isStockOut', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isStockOut
                ? true
                : false
              : false
          })(<Checkbox>Submit Stock Out</Checkbox>)}
        </FormItem>*/}
        {/*<FormItem label={'Roles PEX'}>
          {this.props.form.getFieldDecorator('isPexAdd', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isPexAdd
                ? true
                : false
              : false
          })(<Checkbox>Add Stock</Checkbox>)}
          {this.props.form.getFieldDecorator('isPexEdit', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isPexEdit
                ? true
                : false
              : false
          })(<Checkbox>Edit Stock</Checkbox>)}
          {this.props.form.getFieldDecorator('isPexDelete', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isPexDelete
                ? true
                : false
              : false
          })(<Checkbox>Delete Stock</Checkbox>)}
        </FormItem>
        <FormItem>
          {this.props.form.getFieldDecorator('isPexOut', {
            valuePropName: 'checked',
            initialValue: this.props.editUser
              ? this.props.editUser.isPexOut
                ? true
                : false
              : false
          })(<Checkbox>PEX Unit Out</Checkbox>)}
        </FormItem>*/}
      </Form>
    );
  }
}
const UserForm = Form.create()(UserFormClass);
class ManagerFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Full Name</span>}>
          {this.props.form.getFieldDecorator('name', {
            rules: [
              { required: true, message: 'Manager full name required' },
              {
                pattern: RegExp(
                  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                ),
                message: 'A name and surname is required'
              }
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.name
              : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Number</span>}>
          {this.props.form.getFieldDecorator('number', {
            rules: [
              { required: true, message: 'Manager number required' },
              {
                pattern: RegExp(/\d/g),
                message: 'A valid number is required'
              }
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.number
              : ''
          })(<Input maxLength={10} />)}
        </FormItem>
        <FormItem label={<span>Email</span>}>
          {this.props.form.getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: 'Field entry required'
              },
              {
                type: 'email',
                message: 'A valid email is required.'
              }
            ],
            initialValue: this.props.editManager
              ? this.props.editManager.email
              : ''
          })(<Input />)}
        </FormItem>
      </Form>
    );
  }
}
const ManagerForm = Form.create()(ManagerFormClass);
class BuyerFormClass extends React.Component {
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Full Name</span>}>
          {this.props.form.getFieldDecorator('name', {
            rules: [
              { required: true, message: 'Buyer full name required' },
              {
                pattern: RegExp(
                  /^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/
                ),
                message: 'A name and surname is required'
              }
            ],
            initialValue: this.props.editBuyer ? this.props.editBuyer.name : ''
          })(<Input />)}
        </FormItem>
        <FormItem label={<span>Number</span>}>
          {this.props.form.getFieldDecorator('number', {
            rules: [
              { required: true, message: 'Buyer number required' },
              {
                pattern: RegExp(/\d/g),
                message: 'A valid number is required'
              }
            ],
            initialValue: this.props.editBuyer
              ? this.props.editBuyer.number
              : ''
          })(<Input maxLength={10} />)}
        </FormItem>
        <FormItem label={<span>Email</span>}>
          {this.props.form.getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: 'Field entry required'
              },
              {
                type: 'email',
                message: 'A valid email is required.'
              }
            ],
            initialValue: this.props.editBuyer ? this.props.editBuyer.email : ''
          })(<Input />)}
        </FormItem>
      </Form>
    );
  }
}
const BuyerForm = Form.create()(BuyerFormClass);
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: '',
      editDealer: undefined,
      editManager: undefined,
      editUser: undefined
    };
  }
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllUsers()
      .then(() => {
        this.props
          .findAllManagers()
          .then(() => {
            this.props
              .findAllBuyers()
              .then(() => {
                this.setState({ loading: false });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
    window.addEventListener('resize', this.resizeEvent);
    if (window.cordova) {
      document.addEventListener('backbutton', this.handleBack);
    }
  };

  handleBack = () => {
    if (this.state.addUserVisible) {
      this.setState({ addUserVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener('backbutton', this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  handleSelectUser = e => {
    this.props.setUserDetailsId(e.currentTarget.getAttribute('data'));
    this.props.push('/admin/userdetails');
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let users = this.props.users;
    if (order === 'ascend') {
      users.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      users.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedInfo: sorter
    });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? 'black' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  userForm;
  onAddUser = e => {
    this.setState({ addUserVisible: true, editUser: undefined });
  };
  handleAddUser = e => {
    e.preventDefault();
    this.userForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editUser) {
          values._id = this.state.editUser._id;
        } else {
          values.type = 'user';
          values.password = 'pass';
          values.firstLogin = true;
        }
        values.manager = [values.manager];
        this.props
          .addUser(values)
          .then(() => {
            this.props
              .findAllUsers()
              .then(() => {
                if (this.state.editUser) {
                  Notify('success', 'Successfully saved user');
                } else {
                  Notify('success', 'Successfully added user');
                }
                this.userForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addUserVisible: false,
                  editUser: undefined
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };
  handleEditUser = e => {
    this.setState({ addUserVisible: true, editUser: e });
  };
  handleDeleteUser = e => {
    const id = e.currentTarget.getAttribute('data');
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are your sure you want to delete this user?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteUser(id)
          .then(() => {
            that.props
              .findAllUsers()
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully deleted user');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() { }
    });
  };
  managerForm;
  onAddManager = e => {
    this.setState({ addManagerVisible: true, editManager: undefined });
  };
  handleAddManager = e => {
    e.preventDefault();
    this.managerForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editManager) {
          values._id = this.state.editManager._id;
        } else {
          values.type = 'manager';
          values.password = 'pass';
          values.firstLogin = true;
        }
        this.props
          .addManager(values)
          .then(() => {
            this.props
              .findAllManagers()
              .then(() => {
                if (this.state.editManager) {
                  Notify('success', 'Successfully saved manager');
                } else {
                  Notify('success', 'Successfully added manager');
                }
                this.managerForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addManagerVisible: false,
                  editManager: undefined
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };
  handleEditManager = e => {
    this.setState({ addManagerVisible: true, editManager: e });
  };
  handleDeleteManager = e => {
    const id = e.currentTarget.getAttribute('data');
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are your sure you want to delete this manager?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteUser(id)
          .then(() => {
            that.props
              .findAllManagers()
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully deleted manager');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() { }
    });
  };
  buyerForm;
  onAddBuyer = e => {
    this.setState({ addBuyerVisible: true, editBuyer: undefined });
  };
  handleAddBuyer = e => {
    e.preventDefault();
    this.buyerForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editBuyer) {
          values._id = this.state.editBuyer._id;
        } else {
          values.type = 'buyer';
          values.password = 'pass';
          values.firstLogin = true;
        }
        this.props
          .addBuyer(values)
          .then(() => {
            this.props
              .findAllBuyers()
              .then(() => {
                if (this.state.editBuyer) {
                  Notify('success', 'Successfully saved buyer');
                } else {
                  Notify('success', 'Successfully added buyer');
                }
                this.buyerForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addBuyerVisible: false,
                  editBuyer: undefined
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };
  handleEditBuyer = e => {
    this.setState({ addBuyerVisible: true, editBuyer: e });
  };
  handleDeleteBuyer = e => {
    const id = e.currentTarget.getAttribute('data');
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are your sure you want to delete this buyer?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteUser(id)
          .then(() => {
            that.props
              .findAllBuyers()
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully deleted buyer');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() { }
    });
  };
  render() {
    const { user } = this.props;
    return (
      <div style={{ maxWidth: '90em', margin: 'auto' }}>
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{ textAlign: 'center' }}
        >
          {/*<Col xs={24} md={12} style={{ marginLeft: "-0.8em" }}>
            <TeamOutlined
              style={{ fontSize: 25, color: "#EC8144", marginRight: 7 }}
            />
            <span style={{ fontWeight: 750, fontSize: 25 }}>Manage People</span>
          </Col>*/}
          <Col
            style={{ display: 'flex', justifyContent: 'flex-start' }}
            xs={24}
            md={24}
          >
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              {/* <Button
                type="primary"
                shape="round"
                onClick={this.onAddUser}
                icon={<PlusCircleOutlined style={{ fontSize: '110%' }} />}
                size="large"
                style={{
                  marginRight: '2.5em',
                  height: '3em',
                  width: '9em'
                }}
              >
                Add User
              </Button> */}
              {/*
                   <Button
                type='primary'
                style={{ height: "3em", marginRight: 15 }}
                onClick={this.onAddUser}
              >
                Add User
              </Button><Button
                type='primary'
                style={{ height: "3em", marginRight: 15 }}
                onClick={this.onAddManager}
              >
                Add Manager
              </Button>
              <Button
                type='primary'
                style={{ height: "3em" }}
                onClick={this.onAddBuyer}
              >
                Add Buyer
              </Button>*/}
            </div>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24} style={{ marginBottom: 20, marginTop: 20 }}>
              <span style={{ fontWeight: 750, fontSize: 25 }}>
                Member Profile
              </span>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <StyledTable
                style={{ background: 'white' }}
                rowKey="_id"
                size="middle"
                pagination={false}
                expandedRowRender={
                  window.innerWidth <= 420
                    ? (record, index, indent, expanded) => (
                      <Card
                        size="small"
                        title={record.name}
                      // style={{ width: 300, marginLeft: -35 }}
                      >
                        <p>Number: {record.number}</p>
                        <p>Email: {record.email}</p>
                        <p>Identity Number: {record.identityNumber}</p>
                      </Card>
                    )
                    : null
                }
                dataSource={this.props.users.filter(
                  user => user.email === this.props.user.email
                )}
                onChange={this.handleSort}
              >
                <Column
                  {...this.getColumnSearchProps('username')}
                  title="Username"
                  dataIndex="username"
                  key="username"
                />
                {window.innerWidth > 420 ? (
                  <Column
                    {...this.getColumnSearchProps('name')}
                    title="Full Name"
                    dataIndex="name"
                    key="name"
                  />
                ) : null}
                {window.innerWidth > 420 ? (
                  <Column
                    {...this.getColumnSearchProps('number')}
                    title="Number"
                    dataIndex="number"
                    key="number"
                  />
                ) : null}
                {window.innerWidth > 420 ? (
                  <Column
                    style={{}}
                    {...this.getColumnSearchProps('email')}
                    title="Email"
                    dataIndex="email"
                    key="email"
                  />
                ) : null}
                {/*{window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("brand")}
                        title='Brand'
                        dataIndex='brand'
                        key='brand'
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps("branch")}
                        title='Branch'
                        dataIndex='branch'
                        key='branch'
                      />
                    ) : null}*/}

                {/*{window.innerWidth > 420 ? (
                      <Column
                        width={70}
                        title='Batch Request'
                        render={(text, request) => (
                          <span>{request.isBatch ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={80}
                        title='Submit Stock Out'
                        render={(text, request) => (
                          <span>{request.isStockOut ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={65}
                        title='Add PEX'
                        render={(text, request) => (
                          <span>{request.isPexAdd ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={65}
                        title='Edit PEX'
                        render={(text, request) => (
                          <span>{request.isPexEdit ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={65}
                        title='Del PEX'
                        render={(text, request) => (
                          <span>{request.isPexDelete ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        width={80}
                        title='PEX Unit Out'
                        render={(text, request) => (
                          <span>{request.isPexOut ? "Yes" : "No"}</span>
                        )}
                      />
                    ) : null}*/}
                <Column
                  title="Actions"
                  render={(text, user) => (
                    <span>
                      <Button
                        style={{
                          backgroundColor: 'black',
                          color: 'white',
                          borderRadius: '3em'
                        }}
                        onClick={e => this.handleEditUser(user)}
                      >
                        Edit
                      </Button>
                      {/* <Divider type="vertical" />
                          <DeleteFilled
                            style={{
                              backgroundColor: '#CFD5E3',
                              color: 'white',
                              padding: '0.5em',
                              borderRadius: '3em'
                            }}
                            data={user._id}
                            onClick={this.handleDeleteUser}
                          /> */}
                    </span>
                  )}
                />
              </StyledTable>
            </Col>
          </Row>
        </Spin>
        {this.state.addUserVisible ? (
          <Modal
            title="User"
            visible={this.state.addUserVisible}
            onOk={this.handleAddUser}
            onCancel={e => this.setState({ addUserVisible: false })}
            okText={this.state.editUser ? 'Save' : 'Add User'}
            cancelText="Cancel"
          >
            <UserForm
              managers={this.props.managers}
              editUser={this.state.editUser}
              wrappedComponentRef={form => (this.userForm = form)}
            />
          </Modal>
        ) : null}
        <Modal
          title="Add Manager"
          visible={this.state.addManagerVisible}
          onOk={this.handleAddManager}
          onCancel={e => this.setState({ addManagerVisible: false })}
          okText={this.state.editManager ? 'Save' : 'Add Manager'}
          cancelText="Cancel"
        >
          <ManagerForm
            editManager={this.state.editManager}
            wrappedComponentRef={form => (this.managerForm = form)}
          />
        </Modal>
        <Modal
          title="Add Buyer"
          visible={this.state.addBuyerVisible}
          onOk={this.handleAddBuyer}
          onCancel={e => this.setState({ addBuyerVisible: false })}
          okText={this.state.editBuyer ? 'Save' : 'Add Buyer'}
          cancelText="Cancel"
        >
          <BuyerForm
            editBuyer={this.state.editBuyer}
            wrappedComponentRef={form => (this.buyerForm = form)}
          />
        </Modal>
      </div>
    );
  }
}

const WrappedUsers = Form.create()(Users);

export default WrappedUsers;
