import { getToken } from 'src/redux/selectors';

export function updateBidsList(stock) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'bactive/api/bidding/updatebidslist',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(stock)
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'BIDDING_UPDATE_LIST',
            data: await res.json()
          })
        );
      });
    }).catch(async err => {
      throw err.message;
    });
}

export function updateBidsBasket(stock) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        'bactive/api/bidding/updatebidslist',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(stock)
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'BIDDING_UPDATE_BASKET',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function updateBid(stock) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + 'bactive/api/bidding/updatebid',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(stock)
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'BIDDING_UPDATE_SINGLE',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function placeBid(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + 'bactive/api/bidding/placebid',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(values)
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findBidData(values) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + 'bactive/api/bidding/getbiddata',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'BIDDING_FIND_BIDDATA',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}
