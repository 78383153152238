import { createSelector } from 'reselect';
import { Cookies } from 'react-cookie';

// SUBJECTS
const getNotificationsState = state => state.notifications.notifications;

export const getNotifications = createSelector(
  [getNotificationsState],
  notifications => {
    return notifications;
  }
);

const getMakesStates = state => state.cddata.makes;

export const getMakes = createSelector(
  [getMakesStates],
  makes => {
    return makes;
  }
);

const getModelsStates = state => state.cddata.models;

export const getModels = createSelector(
  [getModelsStates],
  models => {
    return models;
  }
);

const getVariantsStates = state => state.cddata.variants;

export const getVariants = createSelector(
  [getVariantsStates],
  variants => {
    return variants;
  }
);

const getYearsStates = state => state.cddata.years;

export const getYears = createSelector(
  [getYearsStates],
  years => {
    return years;
  }
);

const getCitiesStates = state => state.cddata.cities;

export const getCities = createSelector(
  [getCitiesStates],
  cities => {
    return cities;
  }
);

const getTimeStampStates = state => state.stock.timeStamp;

export const getTimeStamp = createSelector(
  [getTimeStampStates],
  timeStamp => {
    return timeStamp;
  }
);

const getCustomTimeStates = state => state.stock.customTime;

export const getCustomTime = createSelector(
  [getCustomTimeStates],
  customTime => {
    return customTime;
  }
);

const getMyStockStates = state => state.stock.myStock;

export const getMyStock = createSelector(
  [getMyStockStates],
  myStock => {
    return myStock;
  }
);

const getStockDetailsStates = state => state.stock.stockDetails;

export const getStockDetails = createSelector(
  [getStockDetailsStates],
  stockDetails => {
    return stockDetails;
  }
);

const getStockDetailsIdStates = state => state.stock.stockDetailsId;

export const getStockDetailsId = createSelector(
  [getStockDetailsIdStates],
  stockDetailsId => {
    return stockDetailsId;
  }
);

const getStockDetailsAdminStates = state => state.admin.stockDetails;

export const getStockDetailsAdmin = createSelector(
  [getStockDetailsAdminStates],
  stockDetails => {
    return stockDetails;
  }
);

const getStockBidInfoAdminStates = state => state.admin.biddingInfo;

export const getStockBidInfo = createSelector(
  [getStockBidInfoAdminStates],
  biddingInfo => {
    return biddingInfo;
  }
);

const getStockDetailsIdAdminStates = state => state.admin.stockDetailsId;

export const getStockDetailsIdAdmin = createSelector(
  [getStockDetailsIdAdminStates],
  stockDetailsId => {
    return stockDetailsId;
  }
);

const getFromHistoryAdminStates = state => state.admin.fromHistory;

export const getFromHistoryAdmin = createSelector(
  [getFromHistoryAdminStates],
  fromHistory => {
    return fromHistory;
  }
);

const getApprovedStockStates = state => state.admin.approvedStock;

export const getApprovedStock = createSelector(
  [getApprovedStockStates],
  approvedStock => {
    return approvedStock;
  }
);

const getApprovedStockIdsStates = state => state.admin.approvedStockIds;

export const getApprovedStockIds = createSelector(
  [getApprovedStockIdsStates],
  approvedStockIds => {
    return approvedStockIds;
  }
);

const getAuctionHistoryStockStates = state => state.admin.auctionHistoryStock;

export const getAuctionHistoryStock = createSelector(
  [getAuctionHistoryStockStates],
  auctionHistoryStock => {
    return auctionHistoryStock;
  }
);

const getAllStockStates = state => state.admin.allStock;

export const getAllStock = createSelector(
  [getAllStockStates],
  allStock => {
    return allStock;
  }
);

const getDealStockStates = state => state.admin.dealStock;

export const getDealStock = createSelector(
  [getDealStockStates],
  dealStock => {
    return dealStock;
  }
);

const getAllStockIdsStates = state => state.admin.allStockIds;

export const getAllStockIds = createSelector(
  [getAllStockIdsStates],
  allStockIds => {
    return allStockIds;
  }
);

const getCalendarState = state => state.admin.calendar;

export const getCalendar = createSelector(
  [getCalendarState],
  calendar => {
    return calendar;
  }
);

const getDealDoneStockStates = state => state.stock.dealDoneStock;

export const getDealDoneStock = createSelector(
  [getDealDoneStockStates],
  dealDoneStock => {
    return dealDoneStock;
  }
);

const getAuctionWonStockStates = state => state.stock.wonStock;

export const getAuctionWonStock = createSelector(
  [getAuctionWonStockStates],
  wonStock => {
    return wonStock;
  }
);

const getAuctionLostStockStates = state => state.stock.lostStock;

export const getAuctionLostStock = createSelector(
  [getAuctionLostStockStates],
  lostStock => {
    return lostStock;
  }
);

const getAuctionStockStates = state => state.stock.auctionStock;

export const getAuctionStock = createSelector(
  [getAuctionStockStates],
  auctionStock => {
    return auctionStock;
  }
);

const getAuctionStockIdsStates = state => state.stock.auctionStockIds;

export const getAuctionStockIds = createSelector(
  [getAuctionStockIdsStates],
  auctionStockIds => {
    return auctionStockIds;
  }
);

const getBasketStockStates = state => state.stock.basketStock;

export const getBasketStock = createSelector(
  [getBasketStockStates],
  basketStock => {
    return basketStock;
  }
);

const getBasketStockIdsStates = state => state.stock.basketStockIds;

export const getBasketStockIds = createSelector(
  [getBasketStockIdsStates],
  basketStockIds => {
    return basketStockIds;
  }
);

const getEditStockStates = state => state.stock.editStock;

export const getEditStock = createSelector(
  [getEditStockStates],
  editStock => {
    return editStock;
  }
);

export const getToken = () => {
  const cookies = new Cookies();
  return cookies.get('token');
};

const getSidebarCollapsedStates = state => state.status.sidebarCollapsed;

export const getSidebarCollapsed = createSelector(
  [getSidebarCollapsedStates],
  collapsed => {
    return collapsed;
  }
);
const getRig1States = state => state.status.changeRig;

export const getRig1 = createSelector(
  [getRig1States],
  change => {
    return change;
  }
);
const getUserState = state => state.user.user;

export const getUser = createSelector(
  [getUserState],
  user => {
    return user;
  }
);

const getAllUserStatesAdmin = state => state.admin.allUsers;

export const getAllUsers = createSelector(
  [getAllUserStatesAdmin],
  allUsers => {
    return allUsers;
  }
);

const getAllManagerStatesAdmin = state => state.admin.allManagers;

export const getAllManagers = createSelector(
  [getAllManagerStatesAdmin],
  allManagers => {
    return allManagers;
  }
);

const getBidDataState = state => state.user.bidData;

export const getBidData = createSelector(
  [getBidDataState],
  bidData => {
    return bidData;
  }
);

export const checkLoggedIn = createSelector(
  [getUserState],
  user => {
    const cookies = new Cookies();
    if (cookies.get('token') && user) {
      return true;
    } else {
      return false;
    }
  }
);

export const checkRemembered = createSelector(
  [getUserState],
  account => {
    const cookies = new Cookies();
    if (cookies.get('remember') === 'true' && account) {
      return true;
    } else {
      return false;
    }
  }
);

// LOCALE
const getLocaleStates = state => state.locale;

export const getLocale = createSelector(
  [getLocaleStates],
  locale => {
    return locale;
  }
);
