import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { saveAccountInfo, changePassword } from 'src/redux/actions/user';
import { getLocale, getUser } from 'src/redux/selectors';

class AccountSettings extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      user,
      push,
      saveAccountInfo,
      changePassword
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        user={user}
        push={push}
        saveAccountInfo={saveAccountInfo}
        changePassword={changePassword}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  push,
  saveAccountInfo,
  changePassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettings);
