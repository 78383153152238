/* global window */
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory, createMemoryHistory } from "history";
import storage from "redux-persist/es/storage";
import thunk from "redux-thunk";

import reducers from "../reducers";

const configureStore = () => {
  const history = createMemoryHistory({
    initialEntries: ["/"],
    initialIndex: 0,
  });
  // const history = createBrowserHistory();
  const middleware = [thunk, routerMiddleware(history)];
  const config = {
    key: "root",
    storage,
    blacklist: [],
  };
  const reducer = persistCombineReducers(config, {
    router: connectRouter(history),
    ...reducers,
  });
  // if (process.env.NODE_ENV === `development`) {
  //   const { createLogger } = require("redux-logger")
  //   const logger = createLogger({
  //     collapsed: true,
  //     level: "warn",
  //   })
  //   middleware.push(logger)
  // }

  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    connectRouter(history)(reducer),
    composeEnhancer(applyMiddleware(...middleware))
  );

  const persistor = persistStore(store, null, () => {
    store.getState();
  });

  return { persistor, store, history };
};

export default configureStore;
