export const initialState = {
  makes: [],
  models: [],
  variants: [],
  years: [],
  cities: []
};

export default function cddataReducer(state = initialState, action) {
  switch (action.type) {
    case 'CDDATA_FIND_MAKES': {
      if (action.data) {
        const makes = action.data.makes;

        return {
          ...state,
          makes: makes
        };
      }
      return initialState;
    }
    case 'CDDATA_FIND_MODELS': {
      if (action.data) {
        const models = action.data.models;

        return {
          ...state,
          models: models
        };
      }
      return initialState;
    }
    case 'CDDATA_FIND_VARIANTS': {
      if (action.data) {
        const variants = action.data.variants;

        return {
          ...state,
          variants: variants
        };
      }
      return initialState;
    }
    case 'CDDATA_FIND_CITIES': {
      if (action.data) {
        const cities = action.data.cities;

        return {
          ...state,
          cities: cities
        };
      }
      return initialState;
    }
    case 'CDDATA_FIND_YEARS': {
      if (action.data) {
        let years = action.data.years;
        years.sort((a, b) => b - a);
        return {
          ...state,
          years: years
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
