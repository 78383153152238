import { Translations, DEFAULT_LOCALE } from 'src/i18n/i18n';

export default function changeLocale(locale = DEFAULT_LOCALE) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      // Validate locale
      if (!Translations[locale])
        return reject({ message: 'Locale does not exist' });
      return resolve(
        dispatch({
          type: 'LOCALE_REPLACE',
          locale
        })
      );
    }).catch(async err => {
      throw err.message;
    });
}
