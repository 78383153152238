import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  updateBidsList,
  placeBid,
  findBidData
} from 'src/redux/actions/bidding';
import { sendNotification } from 'src/redux/actions/notifications';
import {
  getLocale,
  getAllRequisition,
  getAllRequisitionIds,
  getUser,
  getTimeStamp,
  getBidData
} from 'src/redux/selectors';
import {
  addRequisition,
  deleteRequisition,
  findAllRequisition
} from 'src/redux/actions/requisition';

import { withRouter, Link } from 'react-router-dom';

class Requisition extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      requisition,
      user,
      findAllRequisition,
      addRequisition,
      deleteRequisition,
      sendNotification,

      push,
      history
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        requisition={requisition}
        user={user}
        findAllRequisition={findAllRequisition}
        addRequisition={addRequisition}
        deleteRequisition={deleteRequisition}
        sendNotification={sendNotification}
        push={push}
        history={history}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  requisition: state.requisition.allRequisition || [],
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  findAllRequisition,
  addRequisition,
  deleteRequisition,
  sendNotification,
  push
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Requisition)
);
