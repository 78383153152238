export const initialState = {
  myRequisition: [],
  allRequisition: [],
  basketRequisitionIds: [],
  auctionRequisition: [],
  auctionRequisitionIds: [],
  dealDoneRequisition: [],
  wonRequisition: [],
  lostRequisition: [],
  editRequisition: undefined,
  requisitionDetails: undefined,
  requisitionDetailsId: '',
  customTime: undefined,
  timeStamp: undefined
};

export default function requisitionReducer(state = initialState, action) {
  switch (action.type) {
    case 'REQUISITION_FIND_ALLREQUISITION': {
      if (action.data) {
        const allRequisition = action.data.allRequisition;

        return {
          ...state,
          allRequisition: allRequisition
        };
      }
      return initialState;
    }
    case 'REQUISITIONS_FIND_BASKETREQUISITIONS': {
      if (action.data) {
        const basketRequisition = action.data.basketRequisition;
        let basketRequisitionIds = [];
        basketRequisition.forEach(requisition => {
          basketRequisitionIds.push(requisition._id);
        });
        return {
          ...state,
          basketRequisition: basketRequisition,
          basketRequisitionIds: basketRequisitionIds
        };
      }
      return initialState;
    }
    case 'REQUISITIONS_FIND_REQUISITIONDETAILS': {
      if (action.data) {
        const requisitionDetails = action.data.requisitionDetails;

        return {
          ...state,
          requisitionDetails: requisitionDetails
        };
      }
      return initialState;
    }
    case 'REQUISITIONS_FIND_AUCTIONREQUISITIONS': {
      if (action.data) {
        const auctionRequisition = action.data.auctionRequisition;
        let auctionRequisitionIds = [];
        auctionRequisition.forEach(requisition => {
          auctionRequisitionIds.push(requisition._id);
        });
        return {
          ...state,
          auctionRequisition: auctionRequisition,
          auctionRequisitionIds: auctionRequisitionIds
        };
      }
      return initialState;
    }
    case 'REQUISITIONS_FIND_DEALDONEREQUISITIONS': {
      if (action.data) {
        const dealDoneRequisition = action.data.dealDoneRequisition;
        return {
          ...state,
          dealDoneRequisition: dealDoneRequisition
        };
      }
      return initialState;
    }
    case 'REQUISITIONS_FIND_WONREQUISITIONS': {
      if (action.data) {
        const wonRequisition = action.data.wonRequisition;
        return {
          ...state,
          wonRequisition: wonRequisition
        };
      }
      return initialState;
    }
    case 'REQUISITIONS_FIND_LOSTREQUISITIONS': {
      if (action.data) {
        const lostRequisition = action.data.lostRequisition;
        return {
          ...state,
          lostRequisition: lostRequisition
        };
      }
      return initialState;
    }
    case 'REQUISITIONS_SET_EDITREQUISITION': {
      if (action.data) {
        const editRequisition = action.data;

        return {
          ...state,
          editRequisition: editRequisition
        };
      }
      return initialState;
    }
    case 'REQUISITIONS_SET_REQUISITIONDETAILSID': {
      if (action.data) {
        const id = action.data;
        return {
          ...state,
          requisitionDetailsId: id
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_LIST': {
      if (action.data) {
        let auctionRequisition = state.auctionRequisition;
        let updatedRequisition = action.data.requisition;
        auctionRequisition.map(obj => {
          obj.highestBid = updatedRequisition.find(
            o => o._id === obj._id
          ).highestBid;
          obj.addAuctionMinutes = updatedRequisition.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedRequisition.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          auctionRequisition: auctionRequisition,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_BASKET': {
      if (action.data) {
        let basketRequisition = state.basketRequisition;
        let updatedRequisition = action.data.requisition;
        basketRequisition.map(obj => {
          obj.highestBid = updatedRequisition.find(
            o => o._id === obj._id
          ).highestBid;
          obj.addAuctionMinutes = updatedRequisition.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedRequisition.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          basketRequisition: basketRequisition,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_SINGLE': {
      if (action.data) {
        let requisitionDetails = state.requisitionDetails || {};
        requisitionDetails.highestBid = action.data.requisition.highestBid;
        requisitionDetails.addAuctionMinutes =
          action.data.requisition.addAuctionMinutes;
        const timeStamp = action.data.timeStamp;

        return {
          ...state,
          requisitionDetails: requisitionDetails,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
