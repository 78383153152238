import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLocale, getUser } from 'src/redux/selectors';
import { push } from 'connected-react-router';

class Dashboard extends Component {
  render = () => {
    const { Layout, currentLocale, user, push } = this.props;
    return <Layout currentLocale={currentLocale} user={user} push={push} />;
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
