import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { sendNotification } from 'src/redux/actions/notifications';
import { findAllStock } from 'src/redux/actions/stock';

import { withRouter, Link } from 'react-router-dom';

class Stock extends Component {
  render = () => {
    const {
      Layout,
      stock,
      user,
      findAllStock,

      sendNotification,
      push,

      history
    } = this.props;
    return (
      <Layout
        stock={stock}
        user={user}
        findAllStock={findAllStock}
        sendNotification={sendNotification}
        push={push}
        history={history}
      />
    );
  };
}

const mapStateToProps = state => ({
  stock: state.stock.allStock || [],
  user: state.user.user || {}
});

const mapDispatchToProps = {
  findAllStock,

  sendNotification,
  push
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Stock)
);
