export const initialState = {
  myStock: [],
  allStock: [],
  basketStockIds: [],
  auctionStock: [],
  auctionStockIds: [],
  dealDoneStock: [],
  wonStock: [],
  lostStock: [],
  editStock: undefined,
  stockDetails: undefined,
  stockDetailsId: '',
  customTime: undefined,
  timeStamp: undefined
};

export default function stockReducer(state = initialState, action) {
  switch (action.type) {
    case 'STOCK_FIND_ALLSTOCK': {
      if (action.data) {
        const allStock = action.data.allStock;

        return {
          ...state,
          allStock: allStock
        };
      }
      return initialState;
    }
    case 'STOCKS_FIND_BASKETSTOCKS': {
      if (action.data) {
        const basketStock = action.data.basketStock;
        let basketStockIds = [];
        basketStock.forEach(stock => {
          basketStockIds.push(stock._id);
        });
        return {
          ...state,
          basketStock: basketStock,
          basketStockIds: basketStockIds
        };
      }
      return initialState;
    }
    case 'STOCKS_FIND_STOCKDETAILS': {
      if (action.data) {
        const stockDetails = action.data.stockDetails;

        return {
          ...state,
          stockDetails: stockDetails
        };
      }
      return initialState;
    }
    case 'STOCKS_FIND_AUCTIONSTOCKS': {
      if (action.data) {
        const auctionStock = action.data.auctionStock;
        let auctionStockIds = [];
        auctionStock.forEach(stock => {
          auctionStockIds.push(stock._id);
        });
        return {
          ...state,
          auctionStock: auctionStock,
          auctionStockIds: auctionStockIds
        };
      }
      return initialState;
    }
    case 'STOCKS_FIND_DEALDONESTOCKS': {
      if (action.data) {
        const dealDoneStock = action.data.dealDoneStock;
        return {
          ...state,
          dealDoneStock: dealDoneStock
        };
      }
      return initialState;
    }
    case 'STOCKS_FIND_WONSTOCKS': {
      if (action.data) {
        const wonStock = action.data.wonStock;
        return {
          ...state,
          wonStock: wonStock
        };
      }
      return initialState;
    }
    case 'STOCKS_FIND_LOSTSTOCKS': {
      if (action.data) {
        const lostStock = action.data.lostStock;
        return {
          ...state,
          lostStock: lostStock
        };
      }
      return initialState;
    }
    case 'STOCKS_SET_EDITSTOCK': {
      if (action.data) {
        const editStock = action.data;

        return {
          ...state,
          editStock: editStock
        };
      }
      return initialState;
    }
    case 'STOCKS_SET_STOCKDETAILSID': {
      if (action.data) {
        const id = action.data;
        return {
          ...state,
          stockDetailsId: id
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_LIST': {
      if (action.data) {
        let auctionStock = state.auctionStock;
        let updatedStock = action.data.stock;
        auctionStock.map(obj => {
          obj.highestBid = updatedStock.find(o => o._id === obj._id).highestBid;
          obj.addAuctionMinutes = updatedStock.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedStock.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          auctionStock: auctionStock,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_BASKET': {
      if (action.data) {
        let basketStock = state.basketStock;
        let updatedStock = action.data.stock;
        basketStock.map(obj => {
          obj.highestBid = updatedStock.find(o => o._id === obj._id).highestBid;
          obj.addAuctionMinutes = updatedStock.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedStock.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          basketStock: basketStock,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_SINGLE': {
      if (action.data) {
        let stockDetails = state.stockDetails || {};
        stockDetails.highestBid = action.data.stock.highestBid;
        stockDetails.addAuctionMinutes = action.data.stock.addAuctionMinutes;
        const timeStamp = action.data.timeStamp;

        return {
          ...state,
          stockDetails: stockDetails,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
