export function collapseSidebar() {
  return dispatch =>
    new Promise(resolve =>
      resolve(
        dispatch({
          type: 'SIDEBAR_COLLAPSE'
        })
      )
    );
}
export function updateRig1() {
  return dispatch =>
    dispatch({
      type: 'UPDATE_RIG1'
    });
}
export function updateRig2() {
  return dispatch =>
    dispatch({
      type: 'UPDATE_RIG2'
    });
}
