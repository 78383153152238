import React from 'react';
import { Layout } from 'antd';
import Header from 'src/components/layout/Header';
import BackgroundImage from 'src/static/background.svg';
const { Content } = Layout;

const Template = props => (
  <div>
    <Layout style={{ minHeight: '100vh', overflowX: "hidden" }}>
      <Layout>
        {/* <Header /> */}
        <Content
          style={{
            transition: 'all 0.2s',
            overflow: 'initial',
            position: 'relative',
            ...(props.background && {
              // backgroundImage: `url(${BackgroundImage})`
              backgroundColor: `#F8F8FF`
            })
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  </div>
);

export default Template;
