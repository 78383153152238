import React, { PureComponent } from "react";
import { Layout, Row, Col } from "antd";
import SVG from "react-inlinesvg";
import logo from "src/static/logo.svg";

const Logo = () => <SVG src={logo} />;
export class Header extends PureComponent {
  render() {
    return (
      <Layout.Header style={{ background: "white", height: 94 }}>
        <Row
          type={"flex"}
          align={"middle"}
          justify={"space-between"}
          style={{ height: "100%" }}
        >
          <Col style={{ marginTop: 10 }} span={4}>
            <Logo />
          </Col>
          <Col span={10} />
        </Row>
      </Layout.Header>
    );
  }
}

export default Header;
