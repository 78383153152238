import React from "react";
import {
  SearchOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  DeleteFilled,
  CreditCardOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { Link } from "react-router-dom";
import Logo from "src/static/logo2.svg";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Card,
  Checkbox,
  Badge,
} from "antd";
import { ButtonRadius } from "src/components/elements/Button";
import Highlighter from "react-highlight-words";
import Notify from "src/components/meta/Notification";
import styled from "styled-components";
import { regExpLiteral } from "@babel/types";
import HomeImage from "src/static/Home/home-banner-desktop.png";
import HomeImageMobile from "src/static/Home/home-banner-mobile.png";
import KykNetImage from "src/static/Home/KykNet.png";
import JacarandaImage from "src/static/Home/JacarandaFM.png";
import DstvImage from "src/static/Home/DSTV.png";
import HuisgenootImage from "src/static/Home/HuisGenoot.png";
import Vodacom from "src/static/Home/vodacom.png";
import KingPrice from "src/static/Home/king-price.png";
import Story1Image from "src/static/Home/story-1.png";
import Story2Image from "src/static/Home/story-2.png";
import Story3Image from "src/static/Home/story-3.png";
import Story4Image from "src/static/Home/story-4.png";
import Story5Image from "src/static/Home/story-5.png";
import WhatsappImg from "src/static/Home/whatsapp-img.svg";
import StefanContactImg from "src/static/Home/stefan-contact-image.png";
import StefanFooterImg from "src/static/Home/stefan-footer-image.png";
import StefanFooterImgMobile from "src/static/Home/stefan-footer-image-mobile.png";
import FransImg from "src/static/Home/francoise-img.png";
import MarkLukas from "src/static/Home/MarkLukas.jpg";
import Mishka from "src/static/Home/Mishka.jpg";
import CheckImg from "src/static/Home/CheckCircle_Blue.png";
import MinkiImg from "src/static/Home/minki-img.png";
import BerniceImg from "src/static/Home/bernice-img.png";
import UpgradeImg1 from "src/static/Home/upgrade-1-img.png";
import UpgradeImg2 from "src/static/Home/upgrade-2-img.png";
import UpgradeImg3 from "src/static/Home/upgrade-3-img.png";
import SubscribeBack from "src/static/Home/subscribe-banner.png";
import SubscribeBackMobile from "src/static/Home/subscribe-banner-mobile.png";
import MobileAppImg from "src/static/Home/mobile-app-image.svg";
import SubscribeImg from "src/static/Home/subscribe-button-img.svg";
import YearPlan from "src/static/Home/12-week-img.png";
import WeekPlan from "src/static/Home/year-plan-img.png";
import PremiumPlan from "src/static/Home/premium-plan-img.png";
import Week from "src/static/Home/week-img.png";
import Week0 from "src/static/Home/week-0-img.png";
import Week6 from "src/static/Home/week-6-img.png";
import ReactPlayer from "react-player";
import Week12 from "src/static/Home/week-12-img.png";
import { Modal as SuccessModal } from "react-responsive-modal";
import PerpPlaceholder from "src/static/Home/12week-Poster.png";
import PerpVideo from "src/static/Home/12week-Promo.mp4";
import "react-responsive-modal/styles.css";
import LogoModal from "src/static/Home/main-logo.png";
import { RightOutlined } from "@ant-design/icons";
import $ from "jquery";
import { CgArrowLongRight } from "react-icons/cg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const PayfastLink = "https://www.payfast.co.za/eng/process";
const MerchantID = "16762989";
const MerchantKey = "cfbfm504ddvqp";
const { Column, ColumnGroup } = Table;
const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const numeral = require("numeral");
const { TabPane } = Tabs;

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
const settingsCeleb = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
const settingsPrograms = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1.2,
        initialSlide: 1,
      },
    },
  ],
};
if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const Cards = styled(Card)`
  .ant-card-body {
    display: none !important;
  }
`;
const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0px !important;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid lightgrey;
  border-radius: 0em !important;
  height: 47px !important;
  box-shadow: none !important;
  :hover {
    border-color: red !important;

    box-shadow: none !important;
  }
  :active {
    border-color: red !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: red !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: red !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;

const StyledInput = styled(Input)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1.2em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum","tnum";
  font-feature-settings: "tnum","tnum";
  position: relative;
  height: 65px !important;
  padding: 4px 0px;
  background-color: white !important;
  background-image: none;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 2px;
  font-size: 15px !important;
    font-weight: 500 !important;
  -webkit-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  color: black!important;
  box-shadow: none !important;
  :placeholder {
    color: grey !important;
  }
  :hover {
    border-bottom: 0px solid red !important;
  }
  :active{
    border-bottom: 0px red !important;
  }
  @media only screen and (max-width: 600px) {
  width:100vw;
  margin:auto;
    }
  }
`;

const StyledInput1 = styled(Input)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 0em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  // height: 65px !important;
  // padding: 4px 0px;
  background-color: transparent !important;
  background-image: none;
  border: none;
  border: 0px solid white !important;
  border-bottom: 1px solid black !important;
  border-radius: 0px;
  font-size: 16px !important;
  font-weight: 400 !important;
  -webkit-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: black !important;
  box-shadow: none !important;

  ::placeholder {
    color: grey !important;
    font-weight: 400;
  }
`;
const { Meta } = Card;
class AddContactForm extends React.Component {
  state = {
    signUpVisible: false,
    curDT: new Date().toLocaleString(),
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => this.setState({ fileList });
  selectManual = checked => {
    this.setState({
      manualInput: !this.state.manualInput,
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };

  updateInputValueName(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueName: val,
    });
  }
  updateInputValueNumber(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueNumber: val,
    });
  }
  updateInputValueEmail(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueEmail: val,
    });
  }
  render() {
    const controls = [
      "bold",
      "italic",
      "underline",
      "text-color",
      "separator",
      "link",
      "separator",
      "media",
    ];
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    return (
      <Form layout="vertical">
        <Row>
          <Col span={5} style={{ display: "none" }}>
            <FormItem hasFeedback label={<span>Date</span>}>
              {this.props.form.getFieldDecorator("timeDate", {
                rules: [{}],
                initialValue: this.state.curDT,
              })(
                <StyledInput
                  style={{ width: window.isPhone ? "90%" : "" }}
                  value={this.state.curDT}
                  defaultValue={this.state.curDT}
                />
              )}
            </FormItem>
          </Col>
          <Col span={1} />
        </Row>
        <Row>
          <Col span={24} style={{ display: "none" }}>
            <FormItem hasFeedback>
              {this.props.form.getFieldDecorator("condition", {
                rules: [
                  {
                    required: true,
                    message: "Email Address required",
                  },
                ],
                initialValue: "CONTACT LEAD",
              })(
                <StyledInput
                  style={{ width: window.isPhone ? "90%" : "" }}
                  size="large"
                  placeholder="Email Address"
                />
              )}
            </FormItem>
          </Col>
          <Col xs={24} md={11}>
            <FormItem hasFeedback>
              {this.props.form.getFieldDecorator("eventTitle", {
                rules: [
                  {
                    required: true,
                    message: "Name & Surname required",
                  },
                ],
                initialValue: this.props.editStock
                  ? this.props.editStock.eventTitle
                  : "",
              })(
                <StyledInput
                  style={{ width: window.isPhone ? "90%" : "" }}
                  placeholder="Name & Surname"
                />
              )}
            </FormItem>
          </Col>
          <Col xs={0} md={2} />
          <Col xs={24} md={11}>
            <FormItem hasFeedback>
              {this.props.form.getFieldDecorator("contactNumber", {
                rules: [
                  {
                    max: 10,
                    min: 10,

                    required: true,
                    message: "Number required",
                  },
                ],
                initialValue: this.props.editStock
                  ? this.props.editStock.contactNumber
                  : "",
              })(
                <StyledInput
                  type="number"
                  style={{ width: window.isPhone ? "90%" : "" }}
                  size="large"
                  placeholder="Contact Number"
                />
              )}
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem hasFeedback>
              {this.props.form.getFieldDecorator("leadEmail", {
                rules: [
                  {
                    required: true,
                    type: "email",
                    message: "Email Address required",
                  },
                ],
                initialValue: this.props.editStock
                  ? this.props.editStock.leadEmail
                  : "",
              })(
                <StyledInput
                  type="email"
                  style={{ width: window.isPhone ? "90%" : "" }}
                  size="large"
                  placeholder="Email Address"
                />
              )}
            </FormItem>
          </Col>
        </Row>{" "}
        <Row type="flex" justify="space-between" align="middle" />
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>{" "}
      </Form>
    );
  }
}
const ContactForm = Form.create()(AddContactForm);
class AddStockForm extends React.Component {
  state = {
    signUpVisible: false,
    curDT: new Date().toLocaleString(),
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => this.setState({ fileList });
  selectManual = checked => {
    this.setState({
      manualInput: !this.state.manualInput,
    });
  };

  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };

  updateInputValueName(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueName: val,
    });
  }
  updateInputValueNumber(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueNumber: val,
    });
  }
  updateInputValueEmail(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueEmail: val,
    });
  }
  render() {
    const controls = [
      "bold",
      "italic",
      "underline",
      "text-color",
      "separator",
      "link",
      "separator",
      "media",
    ];
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    return (
      <Row
        style={{
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Col xs={24} md={24}>
          <Form
            style={{
              visibility:
                this.props.signUpButtonVisible === false ? "hidden" : "visible",
            }}
            layout="vertical"
          >
            <Row>
              <Col span={5} style={{ display: "none" }}>
                <FormItem hasFeedback label={<span>Date</span>}>
                  {this.props.form.getFieldDecorator("timeDate", {
                    rules: [{}],
                    initialValue: this.state.curDT,
                  })(
                    <StyledInput
                      value={this.state.curDT}
                      defaultValue={this.state.curDT}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={0} md={1} />
            </Row>
            <Row>
              <Col xs={24} md={24} style={{ display: "none" }}>
                <FormItem hasFeedback style={{ marginBottom: "1em" }}>
                  {this.props.form.getFieldDecorator("condition", {
                    rules: [
                      {
                        required: true,
                        message: "Email Address required",
                      },
                    ],
                    initialValue: "FITT PLAN",
                  })(<StyledInput size="large" placeholder="Email Address" />)}
                </FormItem>
              </Col>
              <Col xs={24} md={11}>
                <FormItem
                  hasFeedback
                  style={{ marginBottom: "1em", marginTop: "0.6em" }}
                >
                  {this.props.form.getFieldDecorator("eventTitle", {
                    rules: [
                      {
                        required: true,
                        message: "Full Name required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.eventTitle
                      : "",
                  })(
                    <StyledInput1
                      style={{ width: window.isPhone ? "90%" : "" }}
                      placeholder="Full Name*"
                      value={this.state.inputValueName}
                      onChange={evt => this.updateInputValueName(evt)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={0} md={2} />
              <Col xs={24} md={11}>
                <FormItem hasFeedback style={{ marginBottom: "1em" }}>
                  {this.props.form.getFieldDecorator("contactNumber", {
                    rules: [
                      {
                        max: 10,
                        min: 10,

                        required: true,
                        message: "Phone Number required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.contactNumber
                      : "",
                  })(
                    <StyledInput1
                      type="number"
                      style={{ width: window.isPhone ? "90%" : "" }}
                      size="large"
                      placeholder="Phone*"
                      value={this.state.inputValueNumber}
                      onChange={evt => this.updateInputValueNumber(evt)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} md={24}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("leadEmail", {
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "Email Address required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.leadEmail
                      : "",
                  })(
                    <StyledInput1
                      style={{ width: window.isPhone ? "90%" : "" }}
                      size="large"
                      placeholder="Email*"
                      value={this.state.inputValueEmail}
                      onChange={evt => this.updateInputValueEmail(evt)}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>{" "}
            <Row type="flex" justify="space-between" align="middle" />
            {/* <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={this.handleCancel}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>{' '} */}
          </Form>
          <form action="https://www.payfast.co.za/eng/process">
            <input type="hidden" name="passphrase" value="payfast" />
            <input type="hidden" name="merchant_id" value={MerchantID} />
            <input type="hidden" name="merchant_key" value={MerchantKey} />
            <input
              type="hidden"
              name="return_url"
              value="https://squatchwebsites.com/bactive/"
            />
            <input
              type="hidden"
              name="cancel_url"
              value="https://squatchwebsites.com/bactive/"
            />
            <input
              type="hidden"
              name="name_first"
              value={this.state.inputValueName}
              onChange={evt => this.updateInputValueName(evt)}
            />
            <input
              type="hidden"
              name="email_address"
              value={this.state.inputValueEmail}
              onChange={evt => this.updateInputValueEmail(evt)}
            />
            <input
              type="hidden"
              name="cell_number"
              value={this.state.inputValueNumber}
              onChange={evt => this.updateInputValueNumber(evt)}
            />
            <input type="hidden" name="amount" value="2500" />
            <input
              type="hidden"
              name="item_name"
              value="12 week transformation plan"
            />
            <input
              type="hidden"
              name="notify_url"
              value="https://squatchwebsites.com/bactive/"
            />
            {this.props.signUpButtonVisible === false ? (
              <Row style={{ position: "absolute", top: "31px", width: "100%" }}>
                <Col sm={24} md={11} style={{ width: "90%", margin: "auto" }}>
                  <button
                    className="Cart__checkOutButton"
                    style={{
                      borderRadius: 5,
                      fontWeight: 500,
                      height: "7em",
                      width: "100%",
                      color: "white",
                      cursor: "pointer",
                      border: "none",
                      fontSize: "14px",
                      backgroundColor: "rgb(56, 100, 135)",
                      // marginTop: "1em",
                      position: "absolute",
                      left: 0,
                      top: window.isPhone ? -21 : 0,
                      // bottom: "3em",
                      fontFamily: "Open Sans",
                    }}
                  >
                    <CreditCardOutlined
                      style={{
                        width: "1.1em",
                        marginRight: "0.5em",
                        marginTop: "-0.3em",
                      }}
                    />{" "}
                    COMPLETE SIGN UP FOR R2,500
                  </button>
                </Col>
                <Col sm={0} md={2} />
                <Col sm={24} md={11} style={{ width: "90%", margin: "auto" }}>
                  <a href="https://wa.me/+27 79 066 0881" target="_blank">
                    <div
                      // className="Cart__checkOutButton"
                      style={{
                        borderRadius: 5,
                        paddingTop: window.isPhone ? "2.2em" : "2.8em",
                        fontWeight: 500,
                        height: "7em",
                        width: "100%",
                        color: "white",
                        cursor: "pointer",
                        border: "none",
                        fontSize: "14px",
                        backgroundColor: "#26C963",
                        // marginTop: "1em",
                        position: "absolute",
                        left: 0,
                        top: window.isPhone ? 95 : 0,
                        // bottom: "3em",
                        fontFamily: "Open Sans",
                      }}
                    >
                      <img
                        alt="example"
                        style={{
                          width: "1.1em",
                          marginRight: "0.5em",
                          marginTop: "-0.3em",
                        }}
                        src={WhatsappImg}
                      />{" "}
                      WHATSAPP FOR FURTHER QUESTIONS
                    </div>
                  </a>
                </Col>
              </Row>
            ) : null}
          </form>
        </Col>
      </Row>
    );
  }
}

const StockForm = Form.create()(AddStockForm);
class AddStockWeekForm extends React.Component {
  state = {
    signUpVisible: false,
    curDT: new Date().toLocaleString(),
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => this.setState({ fileList });
  selectManual = checked => {
    this.setState({
      manualInput: !this.state.manualInput,
    });
  };

  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };

  updateInputValueName(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueName: val,
    });
  }
  updateInputValueNumber(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueNumber: val,
    });
  }
  updateInputValueEmail(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueEmail: val,
    });
  }
  render() {
    const controls = [
      "bold",
      "italic",
      "underline",
      "text-color",
      "separator",
      "link",
      "separator",
      "media",
    ];
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    return (
      <Row
        style={{
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Col xs={24} md={24}>
          <Form layout="vertical">
            <Row>
              <Col span={5} style={{ display: "none" }}>
                <FormItem hasFeedback label={<span>Date</span>}>
                  {this.props.form.getFieldDecorator("timeDate", {
                    rules: [{}],
                    initialValue: this.state.curDT,
                  })(
                    <StyledInput
                      value={this.state.curDT}
                      defaultValue={this.state.curDT}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={0} md={1} />
            </Row>
            <Row>
              <Col xs={24} md={24} style={{ display: "none" }}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("condition", {
                    rules: [
                      {
                        required: true,
                        message: "Email Address required",
                      },
                    ],
                    initialValue: "12 WEEK PLAN",
                  })(<StyledInput size="large" placeholder="Email Address" />)}
                </FormItem>
              </Col>
              <Col xs={24} md={11}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("eventTitle", {
                    rules: [
                      {
                        required: true,
                        message: "Name & Surname required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.eventTitle
                      : "",
                  })(
                    <StyledInput1
                      style={{ width: window.isPhone ? "90%" : "" }}
                      placeholder="Name & Surname"
                      value={this.state.inputValueName}
                      onChange={evt => this.updateInputValueName(evt)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={0} md={2} />
              <Col xs={24} md={11}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("contactNumber", {
                    rules: [
                      {
                        max: 10,
                        min: 10,

                        required: true,
                        message: "Number required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.contactNumber
                      : "",
                  })(
                    <StyledInput1
                      type="number"
                      style={{ width: window.isPhone ? "90%" : "" }}
                      size="large"
                      placeholder="Contact Number"
                      value={this.state.inputValueNumber}
                      onChange={evt => this.updateInputValueNumber(evt)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} md={24}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("leadEmail", {
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "Email Address required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.leadEmail
                      : "",
                  })(
                    <StyledInput1
                      style={{ width: window.isPhone ? "90%" : "" }}
                      size="large"
                      placeholder="Email Address"
                      value={this.state.inputValueEmail}
                      onChange={evt => this.updateInputValueEmail(evt)}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>{" "}
            <Row type="flex" justify="space-between" align="middle" />
            {/* <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={this.handleCancel}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>{' '} */}
          </Form>
          <form action="https://www.payfast.co.za/eng/process">
            <input type="hidden" name="passphrase" value="payfast" />
            <input type="hidden" name="merchant_id" value={MerchantID} />
            <input type="hidden" name="merchant_key" value={MerchantKey} />
            <input
              type="hidden"
              name="return_url"
              value="https://squatchwebsites.com/bactive/"
            />
            <input
              type="hidden"
              name="cancel_url"
              value="https://squatchwebsites.com/bactive/"
            />
            <input
              type="hidden"
              name="name_first"
              value={this.state.inputValueName}
              onChange={evt => this.updateInputValueName(evt)}
            />
            <input
              type="hidden"
              name="email_address"
              value={this.state.inputValueEmail}
              onChange={evt => this.updateInputValueEmail(evt)}
            />
            <input
              type="hidden"
              name="cell_number"
              value={this.state.inputValueNumber}
              onChange={evt => this.updateInputValueNumber(evt)}
            />
            <input type="hidden" name="amount" value="2500" />
            <input type="hidden" name="item_name" value="12 WEEK PLAN" />
            <input
              type="hidden"
              name="notify_url"
              value="https://squatchwebsites.com/bactive/"
            />

            <button
              className="Cart__checkOutButton"
              style={{
                borderRadius: 5,
                fontWeight: 500,
                height: "2.2em",
                width: window.isPhone ? "90%" : "15em",
                color: "white",
                marginLeft: window.isPhone ? "5%" : "4em",
                cursor: "pointer",
                border: "none",
                fontSize: "23px",
                backgroundColor: "Black",
                marginTop: "1em",
                position: "absolute",
                left: 0,
                // bottom: "3em",
                fontFamily: "Open Sans",
              }}
            >
              PAY NOW
            </button>
          </form>
        </Col>
      </Row>
    );
  }
}

const StockWeekForm = Form.create()(AddStockWeekForm);
class AddStockPremiumForm extends React.Component {
  state = {
    signUpVisible: false,
    curDT: new Date().toLocaleString(),
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => this.setState({ fileList });
  selectManual = checked => {
    this.setState({
      manualInput: !this.state.manualInput,
    });
  };

  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };

  updateInputValueName(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueName: val,
    });
  }
  updateInputValueNumber(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueNumber: val,
    });
  }
  updateInputValueEmail(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValueEmail: val,
    });
  }
  render() {
    const controls = [
      "bold",
      "italic",
      "underline",
      "text-color",
      "separator",
      "link",
      "separator",
      "media",
    ];
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    return (
      <Row
        style={{
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Col xs={24} md={24}>
          <Form layout="vertical">
            <Row>
              <Col span={5} style={{ display: "none" }}>
                <FormItem hasFeedback label={<span>Date</span>}>
                  {this.props.form.getFieldDecorator("timeDate", {
                    rules: [{}],
                    initialValue: this.state.curDT,
                  })(
                    <StyledInput
                      value={this.state.curDT}
                      defaultValue={this.state.curDT}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={0} md={1} />
            </Row>
            <Row>
              <Col xs={24} md={24} style={{ display: "none" }}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("condition", {
                    rules: [
                      {
                        required: true,
                        message: "Email Address required",
                      },
                    ],
                    initialValue: "PREMIUM PLAN",
                  })(<StyledInput size="large" placeholder="Email Address" />)}
                </FormItem>
              </Col>
              <Col xs={24} md={11}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("eventTitle", {
                    rules: [
                      {
                        required: true,
                        message: "Name & Surname required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.eventTitle
                      : "",
                  })(
                    <StyledInput1
                      style={{ width: window.isPhone ? "90%" : "" }}
                      placeholder="Name & Surname"
                      value={this.state.inputValueName}
                      onChange={evt => this.updateInputValueName(evt)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={0} md={2} />
              <Col xs={24} md={11}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("contactNumber", {
                    rules: [
                      {
                        max: 10,
                        min: 10,

                        required: true,
                        message: "Number required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.contactNumber
                      : "",
                  })(
                    <StyledInput1
                      type="number"
                      style={{ width: window.isPhone ? "90%" : "" }}
                      size="large"
                      placeholder="Contact Number"
                      value={this.state.inputValueNumber}
                      onChange={evt => this.updateInputValueNumber(evt)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col xs={24} md={24}>
                <FormItem hasFeedback>
                  {this.props.form.getFieldDecorator("leadEmail", {
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "Email Address required",
                      },
                    ],
                    initialValue: this.props.editStock
                      ? this.props.editStock.leadEmail
                      : "",
                  })(
                    <StyledInput1
                      style={{ width: window.isPhone ? "90%" : "" }}
                      size="large"
                      placeholder="Email Address"
                      value={this.state.inputValueEmail}
                      onChange={evt => this.updateInputValueEmail(evt)}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>{" "}
            <Row type="flex" justify="space-between" align="middle" />
            {/* <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={this.handleCancel}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>{' '} */}
          </Form>
          <form action="https://www.payfast.co.za/eng/process">
            <input type="hidden" name="passphrase" value="payfast" />
            <input type="hidden" name="merchant_id" value={MerchantID} />
            <input type="hidden" name="merchant_key" value={MerchantKey} />
            <input
              type="hidden"
              name="return_url"
              value="https://squatchwebsites.com/bactive/"
            />
            <input
              type="hidden"
              name="cancel_url"
              value="https://squatchwebsites.com/bactive/"
            />
            <input
              type="hidden"
              name="name_first"
              value={this.state.inputValueName}
              onChange={evt => this.updateInputValueName(evt)}
            />
            <input
              type="hidden"
              name="email_address"
              value={this.state.inputValueEmail}
              onChange={evt => this.updateInputValueEmail(evt)}
            />
            <input
              type="hidden"
              name="cell_number"
              value={this.state.inputValueNumber}
              onChange={evt => this.updateInputValueNumber(evt)}
            />
            <input type="hidden" name="amount" value="28000" />
            <input type="hidden" name="item_name" value="PREMIUM PLAN" />
            <input
              type="hidden"
              name="notify_url"
              value="https://squatchwebsites.com/bactive/"
            />

            <button
              className="Cart__checkOutButton"
              style={{
                borderRadius: 5,
                fontWeight: 500,
                height: "2.2em",
                width: window.isPhone ? "90%" : "15em",
                color: "white",
                marginLeft: window.isPhone ? "5%" : "4em",
                cursor: "pointer",
                border: "none",
                fontSize: "23px",
                backgroundColor: "Black",
                marginTop: "1em",
                position: "absolute",
                left: 0,
                // bottom: "3em",
                fontFamily: "Open Sans",
              }}
            >
              PAY NOW
            </button>
          </form>
        </Col>
      </Row>
    );
  }
}

const StockFormPremium = Form.create()(AddStockPremiumForm);
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: "",
      editDealer: undefined,
      editManager: undefined,
      editUser: undefined,
      successModal: false,
      buyYearModal: false,
      signUpButtonVisible: true,
      checkoutVisible: true,
      viewMore: false,
      buyWeekModal: false,
      buyPremiumModal: false,
    };
    this.changeValue = this.changeValue.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  componentWillMount = () => {
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };
  handleInputChangeName(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleBack = () => {
    if (this.state.addUserVisible) {
      this.setState({ addUserVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  handleSelectUser = e => {
    this.props.setUserDetailsId(e.currentTarget.getAttribute("data"));
    this.props.push("/admin/userdetails");
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let users = this.props.users;
    if (order === "ascend") {
      users.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      users.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      sortedInfo: sorter,
    });
  };
  updateInputValue(evt) {
    const val = evt.target.value;
    // ...
    this.setState({
      inputValue: val,
    });
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),

    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "black" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });
  StockForm;
  onAddUser = e => {
    this.setState({ addUserVisible: true, editUser: undefined });
  };
  handleAddStock = e => {
    e.preventDefault();
    this.vehiclesForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .addStock(values)
          .then(() => {
            this.vehiclesForm.props.form.resetFields();
            this.setState({
              loading: false,
              signUpButtonVisible: false,
              previewVisible: false,
              checkoutVisible: false,
              signUpVisible: true,
              editStock: undefined,
              currentTab: "All",
            });
          })

          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  handleTabClick = key => {
    this.props.history.push(`/${key}`); // < == router router v4
  };
  componentDidMount() {
    document.title = "Fitt by Stefan";
    document.documentElement.scrollTop = 0;
  }
  onChange = e => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = value => {
    this.setState({
      inputValue: value,
    });
  };
  showModal = () => {
    this.setState({
      visiblePopup: true,
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };
  handleClickDemo(form) {
    this.setState({
      FormChangeDemo: form,
    });
  }
  handleClickCall(form) {
    this.setState({
      FormChangeCall: form,
    });
  }
  showModalDrone = () => {
    this.setState({
      visiblePopupDrone: true,
    });
  };
  handleOk1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };

  handleCancel1 = e => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };
  handleSubmit = values => {
    this.onFinish(values);
  };
  changeValue = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };
  handleOpenYearModal = () => {
    this.setState({ buyYearModal: true, signUpVisible: true });
  };
  handleCloseYearModal = () => {
    this.setState({ buyYearModal: false });
  };
  handleOpenWeekModal = () => {
    this.setState({ buyWeekModal: true, signUpVisible: true });
  };
  handleCloseWeekModal = () => {
    this.setState({ buyWeekModal: false });
  };
  handleOpenPremiumModal = () => {
    this.setState({ buyPremiumModal: true, signUpVisible: true });
  };
  handleClosePremiumModal = () => {
    this.setState({ buyPremiumModal: false });
  };
  onFinish = values => {
    const MSG = {
      to: ["ianels182@gmail.com"],
      from: "info@bactive.co.za",
      //to: ["ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New contact request",
      text: " ",
      html: `

      <style type="text/css">
        body, #bodyTable{background - color:white}
        #emailHeader{background - color:white}
        #emailBody{background - color:#FFFFFF; border:1px solid #CCCCCC;}
        #emailFooter{background - color:#E1E1E1;}
      </style>
      <body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
        <center style="background-color:#E1E1E1;">
          <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
            <tr>
              <td align="center" valign="top" id="bodyCell">
                <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
                  <!-- HEADER ROW // -->
                  <tr>
                    <td align="center" valign="top">
                      <!-- CENTERING TABLE // -->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td align="center" valign="top">
                            <!-- FLEXIBLE CONTAINER // -->
                            <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                              <tr>
                                <td valign="top" width="500" class="flexibleContainerCell">

                                  <!-- CONTENT TABLE // -->
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tr>

                                      <td align="right" valign="middle" class="flexibleContainerBox">
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                          <tr>
                                            <td align="left" class="textContent">
                                              <!-- CONTENT // -->

                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <!-- // FLEXIBLE CONTAINER -->
                          </td>
                        </tr>
                      </table>
                      <!-- // CENTERING TABLE -->
                    </td>
                  </tr>
                  <!-- // END -->
                </table>
                <!-- EMAIL BODY // -->
                <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
                  <!-- MODULE ROW // -->
                  <tr>
                    <td align="center" valign="top">
                      <!-- CENTERING TABLE // -->
                      <table border="0" cellpadding="0" cellspacing="0" style="color:#FFFFFF;" bgcolor="#101010">
                        <tr>
                          <td align="center" valign="top">
                            <!-- FLEXIBLE CONTAINER // -->

                            <table border="0" cellpadding="0" cellspacing="0" width="500" class="flexibleContainer">
                              <tr>
                                <td align="center" valign="top" width="500" class="flexibleContainerCell">

                                  <!-- CONTENT TABLE // -->

                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                                    <td align="center" valign="top" width="500" class="flexibleContainerCell  ">
                                      <img alt="" src="https://i.ibb.co/5BmY3qC/main-logo-4ec06f084b29ddd52e07b0ef1b81f7d6-1.png" width="300" height="100" style="display: block; width:195px; max-width: 355px;; min-width: 189px;;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                                        <td align="center" valign="top" class="textContent">
                                          <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                                          <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new contact request from <br />Fitt by Stefan website</h2>
                                        </td>
                                      </tr>
                                      </table>
                                      <!-- // CONTENT TABLE -->
                                    </td>
                                  </tr>
                                </table>
                                <!-- // FLEXIBLE CONTAINER -->
                              </td>
                            </tr>
                          </table>
                          <!-- // CENTERING TABLE -->
                        </td>
                      </tr>
                      <!-- MODULE ROW // -->
                      <tr mc:hideable>
                        <td align="center" valign="top">
                          <!-- CENTERING TABLE // -->
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
                            <tr>
                              <td align="center" valign="top">
                                <!-- FLEXIBLE CONTAINER // -->
                                <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                                  <tr>
                                    <td valign="top" width="500" class="flexibleContainerCell">
                                      <!-- CONTENT TABLE // -->
                                      <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                                        <tr>
                                          <td align="center" valign="top" class="flexibleContainerBox">
                                            <table border="0" cellspacing="0" cellpadding="0" >

                                              <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                                                <td class="role"><br /><br /><br /><br /><br />
                                                  <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${
                                                    values.name
                                                  }<br />
                                                  <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${
                                                    values.surname
                                                  }<br />
                                                  <strong> Email </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${
                                                    values.email
                                                  }<br />
                                                  <strong> Message </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${
                                                    values.message
                                                  }
                                                  <br /><br /><br />
                                                </td>
                                              </tr>
                                              <table>
                                              </td>
                                            </tr>
                                          </table>
                                          <!-- // CONTENT TABLE -->

                                        </td>
                                      </tr>
                                    </table>
                                    <!-- // FLEXIBLE CONTAINER -->
                                  </td>
                                </tr>
                              </table>
                              <!-- // CENTERING TABLE -->
                            </td>
                          </tr>
                          <!-- // MODULE ROW -->


                          <!-- MODULE ROW // -->

                          <table border="0" cellpadding="0" cellspacing="0" width="100%" style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
                            <tr>
                              <td align="center" valign="top">

                                <table border="0" cellpadding="0" cellspacing="0" width="500" class="flexibleContainer">
                                  <tr>
                                    <td align="center" valign="top" width="500" class="flexibleContainerCell">


                                      <table border="0" cellpadding="30" cellspacing="0" width="100%">


                                        <tr>
                                          <td align="center" valign="top" class="textContent">

                                            <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2022@Fitt by Stefan</h2>

                                          </td>
                                        </tr>
                                      </table>
                                      <!-- // CONTENT TABLE -->

                                    </td>
                                  </tr>
                                </table>
                                <!-- // FLEXIBLE CONTAINER -->
                              </td>
                            </tr>
                          </table>
                          `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async res => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name: "",
        email: "",
        surname: "",
        message: "",
      });
    });
  };
  render() {
    const { user } = this.props;
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Row
            style={{
              background: "white",
              textAlign: "center",
              width: "100vw",
              marginTop: "1.5em",
            }}
          >
            <h1
              style={{
                // fontFamily: "Helvetica",
                width: "100%",
                margin: "auto",
                letterSpacing: "0.01em",
                fontWeight: 700,
                color: "black",
                textAlign: "center",
                fontSize: "clamp(2rem, 4.6vw, 4rem)",
                lineHeight: "1em",
              }}
            >
              12 WEEK TRANSFORMATION
            </h1>
            <br />
            <h1
              style={{
                fontFamily: "Montserrat",
                margin: "auto",
                marginTop: "-0.2em",
                width: "100%",
                fontWeight: 700,
                color: "black",
                textAlign: "center",
                fontSize: "clamp(1.5rem, 3vw, 2.8rem)",
              }}
            >
              PROGRAM
            </h1>
          </Row>

          <Row
            style={{
              margin: "auto",
              marginTop: "2em",
              textAlign: "center",
            }}
          >
            <video
              // style={{ minHeight: "30em" }}
              playing={true}
              controls
              // light={
              //   this.state.frontImage
              //     ? process.env.REACT_APP_GLOBAL_SERVER +
              //       "gnfsa/api/images/" +
              //       this.state.frontImage
              //     : PerpPlaceholder
              // }
              poster={PerpPlaceholder}
              style={{
                objectFit: "cover",
                // border: "solid 2px black",
                maxWidth: "70em",
                width: "90vw",

                margin: "auto",
                borderRadius: "1em",
              }}

              // url={PerpVideo}
            >
              {" "}
              <source
                style={{
                  objectFit: "cover",
                  width: "90vw",
                  maxWidth: "70em",

                  margin: "auto",
                  borderRadius: "1em",
                }}
                src={PerpVideo}
                type="video/mp4"
              />
            </video>
          </Row>
          <Row
            style={{
              width: "100vw",
              textAlign: "center",
              marginTop: "1em",
              marginBottom: "1.5rem",
            }}
          >
            <p
              style={{
                fontSize: "clamp(0.8em, 0.85em, 0.9em)",
                fontFamily: "Montserrat",
                color: "Black",
                margin: "auto",
              }}
            >
              <strong>Stefan van der merwe</strong> - Bsc. Hons Biokinetics
              graduate, Sport Science graduate, Certified Personal trainer and{" "}
              <br />
              Founder of @fittbystefan
            </p>
          </Row>
          {window.isPhone ? (
            <div
              style={{
                maxWidth: "90vw",
                margin: "auto",
              }}
            >
              <div>
                <Row style={{ maxWidth: "70rem", margin: "auto" }}>
                  <Col
                    span={21}
                    style={{
                      backgroundColor: "transparent",
                      margin: "auto",
                      marginTop: "0.5em",
                      // marginBottom: "0.5em",
                      // padding: "1em",
                      borderRadius: "0.5em",
                      border: "none",
                      textAlign: "center",
                      fontSize: 40,
                      color: "Black",
                      textAlign: "left",
                      boxShadow: "rgba(31, 38, 135, 0.37) 0px 0px 16px 0px",
                    }}
                    // hoverable
                    // cover={<img style={{ width: "100%" }} src={FransImg} />}
                  >
                    <Card style={{ borderRadius: "0.2em" }}>
                      <Row>
                        <Col span={24}>
                          <img style={{ width: "100%" }} src={FransImg} />

                          <p
                            style={{
                              fontSize: 12,
                              marginTop: "0.3rem",
                              marginBottom: -2,
                              color: "black",
                            }}
                          >
                            Francois van Coke
                          </p>
                        </Col>

                        <Col span={24}>
                          <p
                            style={{
                              fontSize: "11.5px",
                              marginTop: "1rem",
                              lineHeight: "1.4em",
                              fontFamily: "Montserrat",
                              color: "black",
                            }}
                          >
                            Ek dink die groot probleem met oefen en eetplanne is
                            dat dit gewoonlik ‘n one size fits all plan is, maar
                            Stefan skryf vir jou ‘n oefen en eetplan wat
                            spesifiek vir jou liggaam en kondisies is om vir jou
                            die beste resultate te gee.
                          </p>{" "}
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  <Col
                    span={21}
                    style={{
                      backgroundColor: "transparent",
                      margin: "auto",
                      marginTop: "0.5em",
                      // marginBottom: "0.5em",
                      // padding: "1em",
                      borderRadius: "0.5em",
                      border: "none",
                      textAlign: "center",
                      fontSize: 40,
                      color: "Black",
                      textAlign: "left",
                      boxShadow: "rgba(31, 38, 135, 0.37) 0px 0px 16px 0px",
                    }}
                    // hoverable
                    // cover={<img style={{ width: "100%" }} src={FransImg} />}
                  >
                    <Card style={{ borderRadius: "0.2em" }}>
                      <Row>
                        <Col span={24}>
                          <img style={{ width: "100%" }} src={MinkiImg} />

                          <p
                            style={{
                              fontSize: 12,
                              marginTop: "0.3rem",
                              marginBottom: -2,
                              color: "black",
                            }}
                          >
                            Minki Van der Westhuizen
                          </p>
                        </Col>

                        <Col span={24}>
                          <p
                            style={{
                              fontSize: "11.5px",
                              marginTop: "1rem",
                              lineHeight: "1.4em",
                              fontFamily: "Montserrat",
                              color: "black",
                            }}
                          >
                            Ek is stom geslaan deur die kwaliteit van Stefan se
                            oefen en eetplan, dis so eenvoudig om te vol en die
                            gewigsverlies resultate praat vir hulself!
                          </p>{" "}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col
                    span={21}
                    style={{
                      backgroundColor: "transparent",
                      margin: "auto",
                      marginTop: "0.5em",
                      // marginBottom: "0.5em",
                      // padding: "1em",
                      borderRadius: "0.5em",
                      border: "none",
                      textAlign: "center",
                      fontSize: 40,
                      color: "Black",
                      textAlign: "left",
                      boxShadow: "rgba(31, 38, 135, 0.37) 0px 0px 16px 0px",
                    }}
                    // hoverable
                    // cover={<img style={{ width: "100%" }} src={FransImg} />}
                  >
                    <Card style={{ borderRadius: "0.2em" }}>
                      <Row>
                        <Col span={24}>
                          <img style={{ width: "100%" }} src={MarkLukas} />

                          <p
                            style={{
                              fontSize: 12,
                              marginTop: "0.3rem",
                              marginBottom: -2,
                              color: "black",
                            }}
                          >
                            Mark Lukas
                          </p>
                        </Col>

                        <Col span={24}>
                          <p
                            style={{
                              fontSize: "11.5px",
                              marginTop: "1rem",
                              lineHeight: "1.4em",
                              fontFamily: "Montserrat",
                              color: "black",
                            }}
                          >
                            I honestly could not believe how fast the fat melted
                            off of my body! I always thought that I would have
                            to spend hours in the gym and eat bland boring food
                            to loose weight. Now I know this is not true! The
                            food was great, easy to make and very affordable!
                            Stefan’s program is fantastic and I can definitely
                            recommend it! JUST DO IT!
                          </p>{" "}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col
                    span={21}
                    style={{
                      backgroundColor: "transparent",
                      margin: "auto",
                      marginTop: "0.5em",
                      // marginBottom: "0.5em",
                      // padding: "1em",
                      borderRadius: "0.5em",
                      border: "none",
                      textAlign: "center",
                      fontSize: 40,
                      color: "Black",
                      textAlign: "left",
                      boxShadow: "rgba(31, 38, 135, 0.37) 0px 0px 16px 0px",
                    }}
                    // hoverable
                    // cover={<img style={{ width: "100%" }} src={FransImg} />}
                  >
                    <Card style={{ borderRadius: "0.2em" }}>
                      <Row>
                        <Col span={21}>
                          <img style={{ width: "100%" }} src={Mishka} />

                          <p
                            style={{
                              fontSize: 12,
                              marginTop: "0.3rem",
                              marginBottom: -2,
                              color: "black",
                            }}
                          >
                            Mishka
                          </p>
                        </Col>

                        <Col span={24}>
                          <p
                            style={{
                              fontSize: "11.5px",
                              marginTop: "1rem",
                              lineHeight: "1.4em",
                              fontFamily: "Montserrat",
                              color: "black",
                            }}
                          >
                            I believed that I was just meant to be overweight,
                            it’s in my genetics – I told myself. Stefan showed
                            me that I didn’t need to starve myself to loose
                            weight, he is such a great coach! I have so much
                            confidence now! Can’t even begin to say how
                            lifechanging his program was for me! Thank you
                            Stefan!
                          </p>{" "}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <div
              style={{
                maxWidth: "70rem",
                margin: "auto",
              }}
            >
              <div>
                <Row style={{ maxWidth: "70rem", margin: "auto" }}>
                  <Col
                    sm={18}
                    md={11}
                    style={{
                      backgroundColor: "transparent",
                      margin: "auto",
                      marginTop: "0.5em",
                      marginBottom: "0.5em",
                      // padding: "1em",
                      borderRadius: "0.5em",
                      border: "none",
                      textAlign: "center",
                      fontSize: 40,
                      color: "Black",
                      textAlign: "left",
                      boxShadow: "rgba(31, 38, 135, 0.37) 0px 0px 16px 0px",
                    }}
                    // hoverable
                    // cover={<img style={{ width: "100%" }} src={FransImg} />}
                  >
                    <Card style={{ borderRadius: "0.2em" }}>
                      <Row>
                        <Col span={9}>
                          <img style={{ width: "100%" }} src={FransImg} />
                          <p
                            style={{
                              fontSize: 12,
                              marginTop: "0.3rem",
                              marginBottom: -2,
                              color: "black",
                            }}
                          >
                            Francois van Coke
                          </p>
                        </Col>
                        <Col span={1} />
                        <Col span={14}>
                          <p
                            style={{
                              fontSize: "12.5px",
                              marginTop: "2.7rem",
                              lineHeight: "1.4em",
                              fontFamily: "Montserrat",
                              color: "black",
                            }}
                          >
                            Ek dink die groot probleem met oefen en eetplanne is
                            dat dit gewoonlik ‘n one size fits all plan is, maar
                            Stefan skryf vir jou ‘n oefen en eetplan wat
                            spesifiek vir jou liggaam en kondisies is om vir jou
                            die beste resultate te gee.
                          </p>{" "}
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  <Col
                    sm={18}
                    md={11}
                    style={{
                      backgroundColor: "transparent",
                      margin: "auto",
                      marginTop: "0.5em",
                      marginBottom: "0.5em",
                      // padding: "1em",
                      borderRadius: "0.5em",
                      border: "none",
                      textAlign: "center",
                      fontSize: 40,
                      color: "Black",
                      textAlign: "left",
                      boxShadow: "rgba(31, 38, 135, 0.37) 0px 0px 16px 0px",
                    }}
                    // hoverable
                    // cover={<img style={{ width: "100%" }} src={FransImg} />}
                  >
                    <Card style={{ borderRadius: "0.2em" }}>
                      <Row>
                        <Col span={9}>
                          <img style={{ width: "100%" }} src={MinkiImg} />
                          <p
                            style={{
                              fontSize: 12,
                              marginTop: "0.3rem",
                              marginBottom: -2,
                              color: "black",
                            }}
                          >
                            Minki Van der Westhuizen
                          </p>
                        </Col>
                        <Col span={1} />
                        <Col span={14}>
                          <p
                            style={{
                              fontSize: "12.5px",
                              marginTop: "3rem",
                              lineHeight: "1.4em",
                              fontFamily: "Montserrat",
                              color: "black",
                            }}
                          >
                            Ek is stom geslaan deur die kwaliteit van Stefan se
                            oefen en eetplan, dis so eenvoudig om te vol en die
                            gewigsverlies resultate praat vir hulself!
                          </p>{" "}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ maxWidth: "70rem", margin: "auto" }}>
                  <Col
                    sm={18}
                    md={11}
                    style={{
                      backgroundColor: "transparent",
                      margin: "auto",
                      marginTop: "0.5em",
                      marginBottom: "0.5em",
                      // padding: "1em",
                      borderRadius: "0.5em",
                      border: "none",
                      textAlign: "center",
                      fontSize: 40,
                      color: "Black",
                      textAlign: "left",
                      boxShadow: "rgba(31, 38, 135, 0.37) 0px 0px 16px 0px",
                    }}
                    // hoverable
                    // cover={<img style={{ width: "100%" }} src={FransImg} />}
                  >
                    <Card style={{ borderRadius: "0.2em" }}>
                      <Row>
                        <Col span={9}>
                          <img style={{ width: "100%" }} src={MarkLukas} />
                          <p
                            style={{
                              fontSize: 12,
                              marginTop: "0.3rem",
                              marginBottom: -2,
                              color: "black",
                            }}
                          >
                            Mark Lukas
                          </p>
                        </Col>
                        <Col span={1} />
                        <Col span={14}>
                          <p
                            style={{
                              fontSize: "12.5px",
                              marginTop: "1.5rem",
                              lineHeight: "1.4em",
                              fontFamily: "Montserrat",
                              color: "black",
                            }}
                          >
                            I honestly could not believe how fast the fat melted
                            off of my body! I always thought that I would have
                            to spend hours in the gym and eat bland boring food
                            to loose weight. Now I know this is not true! The
                            food was great, easy to make and very affordable!
                            Stefan’s program is fantastic and I can definitely
                            recommend it! JUST DO IT!
                          </p>{" "}
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  <Col
                    sm={18}
                    md={11}
                    style={{
                      backgroundColor: "transparent",
                      margin: "auto",
                      marginTop: "0.5em",
                      marginBottom: "0.5em",
                      // padding: "1em",
                      borderRadius: "0.5em",
                      border: "none",
                      textAlign: "center",
                      fontSize: 40,
                      color: "Black",
                      textAlign: "left",
                      boxShadow: "rgba(31, 38, 135, 0.37) 0px 0px 16px 0px",
                    }}
                    // hoverable
                    // cover={<img style={{ width: "100%" }} src={FransImg} />}
                  >
                    <Card style={{ borderRadius: "0.2em" }}>
                      <Row>
                        <Col span={9}>
                          <img style={{ width: "100%" }} src={Mishka} />
                          <p
                            style={{
                              fontSize: 12,
                              marginTop: "0.3rem",
                              marginBottom: -2,
                              color: "black",
                            }}
                          >
                            Mishka
                          </p>
                        </Col>
                        <Col span={1} />
                        <Col span={14}>
                          <p
                            style={{
                              fontSize: "12.5px",
                              marginTop: "1.5rem",
                              lineHeight: "1.4em",
                              fontFamily: "Montserrat",
                              color: "black",
                            }}
                          >
                            I believed that I was just meant to be overweight,
                            it’s in my genetics – I told myself. Stefan showed
                            me that I didn’t need to starve myself to loose
                            weight, he is such a great coach! I have so much
                            confidence now! Can’t even begin to say how
                            lifechanging his program was for me! Thank you
                            Stefan!
                          </p>{" "}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          <Row style={{ width: "100vw" }}>
            <p
              style={{
                fontFamily: "Montserrat",
                margin: "auto",
                marginTop: "2em",
                fontWeight: 700,
                color: "White",
                fontSize: "clamp(1.5rem, 3vw, 2.8rem)",
                marginBottom: "0.5em",
                backgroundColor: "rgb(66, 148, 211)",
                borderRadius: "1rem",
                padding: "0.2rem 1rem",
              }}
            >
              What to expect
            </p>
          </Row>
          <div
            style={{
              width: "90vw",
              margin: "auto",
              padding: "1em",
              marginTop: "0.5em",
              backgroundColor: "#F1F1F1",
              maxWidth: "70rem",
              margin: "auto",
              borderRadius: "1em",
            }}
          >
            <Row style={{ margin: "auto", padding: "0.5em" }}>
              <Col span={24}>
                <h1
                  style={{
                    fontWeight: 700,
                    fontSize: "20px",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  Workout plan
                </h1>
                <p
                  style={{
                    fontSize: "12.5px",
                    marginLeft: "1.5em",
                    marginTop: "0.5em",
                    lineHeight: "1.4em",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  <img
                    alt="example"
                    style={{
                      width: "1.1em",
                      marginRight: "0.5em",
                      marginLeft: "-1.5em",
                      marginTop: "-0.3em",
                    }}
                    src={CheckImg}
                  />
                  An extremely effective, fully custom written transformation
                  workout plan.
                </p>
                <p
                  style={{
                    fontSize: "12.5px",
                    marginLeft: "1.5em",
                    marginTop: "-0em",
                    lineHeight: "1.4em",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  <img
                    alt="example"
                    style={{
                      width: "1.1em",
                      marginRight: "0.5em",
                      marginLeft: "-1.5em",
                      marginTop: "-0.3em",
                    }}
                    src={CheckImg}
                  />
                  Based on your body type, goals, injuries, AND medical
                  conditions.
                </p>
                <p
                  style={{
                    fontSize: "12.5px",
                    marginLeft: "1.5em",
                    marginTop: "-0em",
                    lineHeight: "1.4em",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  <img
                    alt="example"
                    style={{
                      width: "1.1em",
                      marginRight: "0.5em",
                      marginLeft: "-1.5em",
                      marginTop: "-0.3em",
                    }}
                    src={CheckImg}
                  />
                  This plan can be written for training in the gym or at home,
                  anything from 25-60 minutes.
                </p>
              </Col>
              <Col span={24} style={{ marginTop: "1em" }}>
                <h1
                  style={{
                    fontWeight: 700,
                    fontSize: "20px",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  Meal plan
                </h1>
                <p
                  style={{
                    fontSize: "12.5px",
                    marginLeft: "1.5em",
                    marginTop: "0.5em",
                    lineHeight: "1.4em",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  <img
                    alt="example"
                    style={{
                      width: "1.1em",
                      marginRight: "0.5em",
                      marginLeft: "-1.5em",
                      marginTop: "-0.3em",
                    }}
                    src={CheckImg}
                  />
                  A complete comprehensive- dietitian approved custom written
                  meal plan that will help you melt away fat.
                </p>
                <p
                  style={{
                    fontSize: "12.5px",
                    marginLeft: "1.5em",
                    marginTop: "-0em",
                    lineHeight: "1.4em",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  <img
                    alt="example"
                    style={{
                      width: "1.1em",
                      marginRight: "0.5em",
                      marginLeft: "-1.5em",
                      marginTop: "-0.3em",
                    }}
                    src={CheckImg}
                  />
                  Based on your body type, goals, age, gender, and preferences.
                </p>
                <p
                  style={{
                    fontSize: "12.5px",
                    marginLeft: "1.5em",
                    marginTop: "-0em",
                    lineHeight: "1.4em",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  <img
                    alt="example"
                    style={{
                      width: "1.1em",
                      marginRight: "0.5em",
                      marginLeft: "-1.5em",
                      marginTop: "-0.3em",
                    }}
                    src={CheckImg}
                  />
                  Meal examples, snack examples AND an ever-growing recipe
                  library.
                </p>
              </Col>
              <Col span={24} style={{ marginTop: "1em" }}>
                <h1
                  style={{
                    fontWeight: 700,
                    fontSize: "20px",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  Private online coach
                </h1>
                <p
                  style={{
                    fontSize: "12.5px",
                    marginLeft: "1.5em",
                    marginTop: "0.5em",
                    lineHeight: "1.4em",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  <img
                    alt="example"
                    style={{
                      width: "1.1em",
                      marginRight: "0.5em",
                      marginLeft: "-1.5em",
                      marginTop: "-0.3em",
                    }}
                    src={CheckImg}
                  />
                  Best for last - Me as your Private online coach!
                </p>
                <p
                  style={{
                    fontSize: "12.5px",
                    marginLeft: "1.5em",
                    marginTop: "-0em",
                    lineHeight: "1.4em",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  <img
                    alt="example"
                    style={{
                      width: "1.1em",
                      marginRight: "0.5em",
                      marginLeft: "-1.5em",
                      marginTop: "-0.3em",
                    }}
                    src={CheckImg}
                  />
                  There to keep you accountable!
                </p>
                <p
                  style={{
                    fontSize: "12.5px",
                    marginLeft: "1.5em",
                    marginTop: "-0em",
                    lineHeight: "1.4em",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  <img
                    alt="example"
                    style={{
                      width: "1.1em",
                      marginRight: "0.5em",
                      marginLeft: "-1.5em",
                      marginTop: "-0.3em",
                    }}
                    src={CheckImg}
                  />
                  Answer your health and fitness questions.
                </p>
                <p
                  style={{
                    fontSize: "12.5px",
                    marginLeft: "1.5em",
                    marginTop: "-0em",
                    lineHeight: "1.4em",
                    fontFamily: "Montserrat",
                    color: "black",
                  }}
                >
                  <img
                    alt="example"
                    style={{
                      width: "1.1em",
                      marginRight: "0.5em",
                      marginLeft: "-1.5em",
                      marginTop: "-0.3em",
                    }}
                    src={CheckImg}
                  />
                  And most importantly make sure that you reach your
                  transformation goals!
                </p>
              </Col>
            </Row>
          </div>
          <Row style={{ width: "100vw" }}>
            {this.state.signUpButtonVisible ? (
              <div style={{ margin: "auto" }}>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    margin: "auto",
                    marginTop: "2em",
                    fontWeight: 700,
                    color: "White",
                    fontSize: "clamp(1.5rem, 3vw, 2.8rem)",
                    marginBottom: "0.5em",
                    backgroundColor: "rgb(66, 148, 211)",
                    borderRadius: "1rem",
                    padding: "0.2rem 1rem",
                  }}
                >
                  Get started
                </p>
              </div>
            ) : (
              <div style={{ margin: "auto" }}>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    margin: "auto",
                    marginTop: "2em",
                    fontWeight: 700,
                    color: "White",
                    fontSize: "clamp(1.5rem, 3vw, 2.8rem)",
                    marginBottom: "0.5em",
                    backgroundColor: "rgb(66, 148, 211)",
                    borderRadius: "1rem",
                    padding: "0.2rem 1rem",
                  }}
                >
                  Proceed to Checkout
                </p>
              </div>
            )}
          </Row>

          <div
            style={{
              maxWidth: "100em",
              // width: "100vw",
              margin: "auto",
              textAlign: "center",
              // minHeight: "40em",
              // height: "48.9em",
            }}
          >
            <div
              style={{
                width: "90vw",
                maxWidth: "70rem",
                height: window.isPhone ? "21em" : "17em",
                margin: "auto",
                marginBottom: "3em",
                textAlign: "center",
                marginTop: "0.5em",
                backgroundColor: "rgb(241, 241, 241)",
                borderRadius: "1em",
                paddingBottom: "2em",
                paddingTop: "2em",
                paddingLeft: window.isPhone ? "" : "5em",
                paddingRight: window.isPhone ? "" : "5em",
              }}
            >
              <StockForm
                editStock={this.state.editStock}
                signUpButtonVisible={this.state.signUpButtonVisible}
                wrappedComponentRef={form => (this.vehiclesForm = form)}
              />
              {this.state.signUpButtonVisible ? (
                <ButtonRadius
                  onClick={this.handleAddStock}
                  style={{
                    borderRadius: 5,
                    fontWeight: 500,
                    width: "72vw",
                    maxWidth: "62rem",
                    height: "2em",
                    margin: "auto",
                    color: "white",
                    cursor: "pointer",
                    border: "none",
                    fontSize: "16px",
                    backgroundColor: "rgb(56, 100, 135)",
                    // marginTop: "1em",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    // bottom: "3em",
                    fontFamily: "Open Sans",
                  }}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  SIGN UP
                </ButtonRadius>
              ) : null}
            </div>
          </div>

          <SuccessModal
            open={this.state.buyYearModal}
            onClose={this.handleCloseYearModal}
            center
          >
            <Spin spinning={this.state.loading}>
              <div
                style={{
                  maxWidth: "100em",
                  // width: "100vw",
                  margin: "auto",
                  textAlign: "center",
                  // minHeight: "40em",
                  // height: "48.9em",
                }}
              >
                <div
                  style={{
                    maxWidth: "50em",
                    height: window.isPhone ? "35em" : "33em",
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "6em",
                    backgroundColor: this.state.signUpButtonVisible
                      ? "#3A393A"
                      : "white",
                    borderRadius: "1em",
                    paddingBottom: "2em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      paddingTop: "2em",
                    }}
                  >
                    <Col xs={24} md={24}>
                      {this.state.signUpButtonVisible ? (
                        <div>
                          <h1
                            style={{
                              color: "white",
                              margin: "auto",
                              marginTop: "1em",
                              marginBottom: "1em",
                              fontWeight: 500,
                              fontSize: window.isPhone ? "20px" : "35px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Sign Up for our<strong> FITT PLAN</strong>
                          </h1>
                        </div>
                      ) : (
                        <div>
                          <h1
                            style={{
                              color: this.state.signUpButtonVisible
                                ? "white"
                                : "#3A393A",
                              margin: "auto",
                              marginTop: this.state.signUpButtonVisible
                                ? "1em"
                                : "2.2em",
                              marginBottom: this.state.signUpButtonVisible
                                ? "1em"
                                : "-3em",
                              fontWeight: 500,
                              fontSize: "36px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Proceed to<strong> Checkout</strong>
                          </h1>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <StockForm
                    editStock={this.state.editStock}
                    wrappedComponentRef={form => (this.vehiclesForm = form)}
                  />
                </div>
              </div>
              {this.state.signUpButtonVisible ? (
                <ButtonRadius
                  onClick={this.handleAddStock}
                  style={{
                    borderRadius: 5,
                    fontWeight: 500,
                    height: "2.2em",
                    width: window.isPhone ? "90%" : "50%",
                    marginLeft: window.isPhone ? "5%" : "7em",
                    color: "#3A393A",
                    border: "none",
                    fontSize: "23px",
                    backgroundColor: "white",
                    // marginTop: "1em",
                    fontFamily: "Open Sans",
                    position: "absolute",
                    bottom: window.isPhone ? "1.2em" : "2em",
                  }}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  Continue
                </ButtonRadius>
              ) : null}
            </Spin>
          </SuccessModal>
          <SuccessModal
            open={this.state.buyWeekModal}
            onClose={this.handleCloseWeekModal}
            center
          >
            <Spin spinning={this.state.loading}>
              <div
                style={{
                  maxWidth: "100em",
                  // width: "100vw",
                  margin: "auto",
                  textAlign: "center",
                  // minHeight: "40em",
                  // height: "48.9em",
                }}
              >
                <div
                  style={{
                    maxWidth: "50em",
                    height: window.isPhone ? "35em" : "33em",
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "6em",
                    backgroundColor: this.state.signUpButtonVisible
                      ? "#3A393A"
                      : "white",
                    borderRadius: "1em",
                    paddingBottom: "2em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      paddingTop: "2em",
                    }}
                  >
                    <Col xs={24} md={24}>
                      {this.state.signUpButtonVisible ? (
                        <div>
                          <h1
                            style={{
                              color: "white",
                              margin: "auto",
                              marginTop: "1em",
                              marginBottom: "1em",
                              fontWeight: 500,
                              fontSize: window.isPhone ? "20px" : "35px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Sign Up for our<strong> 12 WEEK PLAN</strong>
                          </h1>
                        </div>
                      ) : (
                        <div>
                          <h1
                            style={{
                              color: this.state.signUpButtonVisible
                                ? "white"
                                : "#3A393A",
                              margin: "auto",
                              marginTop: this.state.signUpButtonVisible
                                ? "1em"
                                : "2.2em",
                              marginBottom: this.state.signUpButtonVisible
                                ? "1em"
                                : "-3em",
                              fontWeight: 500,
                              fontSize: "36px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Proceed to<strong> Checkout</strong>
                          </h1>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <StockWeekForm
                    editStock={this.state.editStock}
                    wrappedComponentRef={form => (this.vehiclesForm = form)}
                  />
                </div>
              </div>
              {this.state.signUpButtonVisible ? (
                <ButtonRadius
                  onClick={this.handleAddStock}
                  style={{
                    borderRadius: 5,
                    fontWeight: 500,
                    height: "2.2em",
                    width: window.isPhone ? "90%" : "50%",
                    marginLeft: window.isPhone ? "5%" : "7em",
                    color: "#3A393A",
                    border: "none",
                    fontSize: "23px",
                    backgroundColor: "white",
                    // marginTop: "1em",
                    fontFamily: "Open Sans",
                    position: "absolute",
                    bottom: window.isPhone ? "1.2em" : "2em",
                  }}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  Continue
                </ButtonRadius>
              ) : null}
            </Spin>
          </SuccessModal>
          <SuccessModal
            open={this.state.buyPremiumModal}
            onClose={this.handleClosePremiumModal}
            center
          >
            <Spin spinning={this.state.loading}>
              <div
                style={{
                  maxWidth: "100em",
                  // width: "100vw",
                  margin: "auto",
                  textAlign: "center",
                  // minHeight: "40em",
                  // height: "48.9em",
                }}
              >
                <div
                  style={{
                    maxWidth: "70rem",
                    height: "33em",
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "6em",
                    backgroundColor: this.state.signUpButtonVisible
                      ? "#3A393A"
                      : "white",
                    borderRadius: "1em",
                    paddingBottom: "2em",
                    paddingLeft: window.isPhone ? "" : "5em",
                    paddingRight: window.isPhone ? "" : "5em",
                  }}
                >
                  <Row
                    style={{
                      margin: "auto",
                      textAlign: "center",
                      paddingTop: "2em",
                    }}
                  >
                    <Col xs={24} md={24}>
                      {this.state.signUpButtonVisible ? (
                        <div>
                          <h1
                            style={{
                              color: "white",
                              margin: "auto",
                              marginTop: "1em",
                              marginBottom: "1em",
                              fontWeight: 500,
                              fontSize: window.isPhone ? "20px" : "35px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Sign Up for our<strong> PREMIUM PLAN</strong>
                          </h1>
                        </div>
                      ) : (
                        <div>
                          <h1
                            style={{
                              color: this.state.signUpButtonVisible
                                ? "white"
                                : "#3A393A",
                              margin: "auto",
                              marginTop: this.state.signUpButtonVisible
                                ? "1em"
                                : "2.2em",
                              marginBottom: this.state.signUpButtonVisible
                                ? "1em"
                                : "-3em",
                              fontWeight: 500,
                              fontSize: "36px",
                              fontFamily: "Open Sans",
                            }}
                          >
                            Proceed to<strong> Checkout</strong>
                          </h1>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <StockFormPremium
                    editStock={this.state.editStock}
                    wrappedComponentRef={form => (this.vehiclesForm = form)}
                  />
                </div>
              </div>
              {this.state.signUpButtonVisible ? (
                <ButtonRadius
                  onClick={this.handleAddStock}
                  style={{
                    borderRadius: 5,
                    fontWeight: 500,
                    height: "2.2em",
                    width: window.isPhone ? "90%" : "50%",
                    marginLeft: window.isPhone ? "5%" : "7em",
                    color: "#3A393A",
                    border: "none",
                    fontSize: "23px",
                    backgroundColor: "white",
                    // marginTop: "1em",
                    fontFamily: "Open Sans",
                    position: "absolute",
                    bottom: window.isPhone ? "1.2em" : "2em",
                  }}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                >
                  Continue
                </ButtonRadius>
              ) : null}
            </Spin>
          </SuccessModal>
          <SuccessModal
            open={this.state.successModal}
            onClose={this.handleCloseSuccessModal}
            center
          >
            <img src={LogoModal} alt="Logo" style={{ width: "65%" }} />
            <h3
              style={{
                fontFamily: "Crimson Text",
                marginTop: "1em",
                color: "white",
                textAlign: "center",
              }}
            >
              Thank you for contacting
              <span>
                <br />
                Fitt by Stefan
              </span>{" "}
              <br />
              <span style={{ color: "white" }}>
                We will be in touch shortly.
              </span>{" "}
              <br />
            </h3>{" "}
            <br />
            <Button
              onClick={this.handleCloseSuccessModal}
              style={{
                margin: "auto",
                height: "2.9em",
                fontFamily: "Nunito",
                fontSize: "1rem",
                fontWeight: 500,
                borderRadius: "5em",
                backgroundColor: "white",
                color: "black",
                border: "none",
                marginBottom: "1em",
                paddingLeft: "3em",
                paddingRight: "3em",
                marginRight: "0em",
                textShadow: "none",
              }}
              type="primary"
            >
              Close
            </Button>{" "}
          </SuccessModal>
          {/* <Link style={{}} to="/signin">
            Sign In
          </Link> */}
        </Spin>
      </div>
    );
  }
}

const WrappedUsers = Form.create()(Users);

export default WrappedUsers;
