import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  updateBidsList,
  placeBid,
  findBidData
} from 'src/redux/actions/bidding';
import { sendNotification } from 'src/redux/actions/notifications';
import {
  getLocale,
  getAllStock,
  getAllStockIds,
  getUser,
  getTimeStamp,
  getBidData
} from 'src/redux/selectors';
import { addStock, deleteStock, findAllStock } from 'src/redux/actions/stock';
import { findTechnicians } from 'src/redux/actions/request';
import { withRouter, Link } from 'react-router-dom';

class Stock extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      stock,
      user,
      findAllStock,
      addStock,
      deleteStock,
      sendNotification,

      findTechnicians,

      push,
      history
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        stock={stock}
        user={user}
        findAllStock={findAllStock}
        addStock={addStock}
        deleteStock={deleteStock}
        sendNotification={sendNotification}
        push={push}
        history={history}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  stock: state.stock.allStock || [],
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  findAllStock,
  addStock,
  deleteStock,
  sendNotification,
  push
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Stock)
);
