/* global window */
import React from 'react';
import { StockOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Cookies } from 'react-cookie';
import { getSidebarCollapsed } from 'src/redux/selectors';
import { collapseSidebar } from 'src/redux/actions/status';
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#EC8144"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#EC8144"
    />
  </svg>
);
const { Sider } = Layout;

const StyledLink = styled(Link)`
  color: #fff;
  :hover {
    text-decoration: none !important;
  }
  .anticon {
    color: #ec8144;
  }
`;

const Sidebar = props => {
  const signOut = () => {
    if (window.cordova) {
      window.FirebasePlugin.getToken(
        token => {
          window.pubnub.push.deleteDevice(
            {
              device: token,
              pushGateway: 'gcm' // apns, gcm, mpns
            },
            function(status) {
              if (status.error) {
                console.log('operation failed w/ error:', status);
              }
            }
          );
        },
        error => {
          console.error(error);
        }
      );
    }

    const cookies = new Cookies();
    cookies.remove('token');
    cookies.remove('basic');
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Sider
      collapsedWidth="0"
      trigger={null}
      collapsible
      collapsed={props.collapsed}
      // width={200}
      style={{
        // border: '1px solid #EC81443b',
        zIndex: 9,
        position: 'fixed',
        background: 'white',
        height: 'calc(100vh - 40px)',
        left: 0,
        top: 40
      }}
    >
      <Menu
        style={{ background: 'none' }}
        theme="light"
        mode="inline"
        defaultSelectedKeys={['requisition']}
      >
        {/* <Menu.Item onClick={props.collapseSidebar} key="stock">
          <StyledLink to="/buyer/stock">
            <Icon
              type="stock"
              style={{ fontSize: '20px', paddingRight: '12px' }}
            />
            <span style={{ fontSize: '15px' }}>Stock</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item onClick={props.collapseSidebar} key="pexunits">
          <StyledLink to="/buyer/pexunits">
            <Icon
              type="stock"
              style={{ fontSize: '20px', paddingRight: '12px' }}
            />
            <span style={{ fontSize: '15px' }}>PEX Units</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item onClick={props.collapseSidebar} key="request">
          <StyledLink to="/buyer/requests">
            <Icon
              type="check"
              style={{ fontSize: '20px', paddingRight: '12px' }}
            />
            <span style={{ fontSize: '15px' }}>Requests</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item onClick={props.collapseSidebar} key="pexrequest">
          <StyledLink to="/buyer/pexrequests">
            <Icon
              type="check"
              style={{ fontSize: '20px', paddingRight: '12px' }}
            />
            <span style={{ fontSize: '15px' }}>PEX Requests</span>
          </StyledLink>
        </Menu.Item> */}
        <Menu.Item onClick={props.collapseSidebar} key="requisition">
          <StyledLink to="/buyer/requisition">
            <StockOutlined style={{ fontSize: '20px', paddingRight: '12px' }} />
            <span style={{ fontSize: '15px' }}>Requisitions</span>
          </StyledLink>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

const mapStateToProps = state => {
  return {
    collapsed: getSidebarCollapsed(state)
  };
};

const mapDispatchToProps = {
  collapseSidebar
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
