export const initialState = {
  allStock: [],
  allStockIds: [],
  dealStock: [],
  approvedStock: [],
  approvedStockIds: [],
  auctionHistoryStock: [],
  stockDetails: undefined,
  stockDetailsId: '',
  allUsers: [],
  allManagers: [],
  allBuyers: [],
  calendar: {},
  fromHistory: false,
  biddingInfo: [],
  rig1: false
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADMIN_FIND_CALENDAR': {
      if (action.data) {
        const calendar = action.data.calendar;
        return {
          ...state,
          calendar: calendar
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_VEHICLEDETAILS': {
      if (action.data) {
        const stockDetails = action.data.stockDetails;

        return {
          ...state,
          stockDetails: stockDetails
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_VEHICLEBIDINFO': {
      if (action.data) {
        const biddingInfo = action.data.biddingInfo;

        return {
          ...state,
          biddingInfo: biddingInfo
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLVEHICLES': {
      if (action.data) {
        const allStock = action.data.allStock;
        let allStockIds = [];
        allStock.forEach(stock => {
          allStockIds.push(stock._id);
        });
        return {
          ...state,
          allStock: allStock,
          allStockIds: allStockIds
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_DEALVEHICLES': {
      if (action.data) {
        const dealStock = action.data.dealStock;
        return {
          ...state,
          dealStock: dealStock
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_APPROVEDVEHICLES': {
      if (action.data) {
        const approvedStock = action.data.approvedStock;
        let approvedStockIds = [];
        approvedStock.forEach(stock => {
          approvedStockIds.push(stock._id);
        });
        return {
          ...state,
          approvedStock: approvedStock,
          approvedStockIds: approvedStockIds
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_AUCTIONHISTORYVEHICLES': {
      if (action.data) {
        const auctionHistoryStock = action.data.auctionHistoryStock;
        return {
          ...state,
          auctionHistoryStock: auctionHistoryStock
        };
      }
      return initialState;
    }
    case 'ADMIN_SET_VEHICLEDETAILSID': {
      if (action.data) {
        const id = action.data;
        return {
          ...state,
          stockDetailsId: id
        };
      }
      return initialState;
    }
    case 'ADMIN_SET_FROMHISTORY': {
      if (action.data) {
        const boolean = action.data;
        return {
          ...state,
          fromHistory: boolean
        };
      }
      return initialState;
    }
    case 'ADMIN_BIDDING_UPDATE_LIST': {
      if (action.data) {
        let auctionStock = state.auctionStock;
        let updatedStock = action.data.stock;
        auctionStock.map(obj => {
          obj.highestBid = updatedStock.find(o => o._id === obj._id).highestBid;
          obj.addAuctionMinutes = updatedStock.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
        });
        const timeStamp = action.data.timeStamp;
        return {
          ...state,
          auctionStock: auctionStock,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    case 'ADMIN_BIDDING_UPDATE_SINGLE': {
      if (action.data) {
        let stockDetails = state.stockDetails || {};
        stockDetails.highestBid = action.data.stock.highestBid;
        stockDetails.addAuctionMinutes = action.data.stock.addAuctionMinutes;
        const timeStamp = action.data.timeStamp;

        return {
          ...state,
          stockDetails: stockDetails,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLUSERS': {
      if (action.data) {
        const allUsers = action.data.allUsers;
        return {
          ...state,
          allUsers: allUsers
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLMANAGERS': {
      if (action.data) {
        const allManagers = action.data.allManagers;
        return {
          ...state,
          allManagers: allManagers
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLBUYERS': {
      if (action.data) {
        const allBuyers = action.data.allBuyers;
        return {
          ...state,
          allBuyers: allBuyers
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
