import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Spin, Input, Tabs } from 'antd';
import { ButtonRadius } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
import CarPic from 'src/static/icons/login-car-pic1.png';

const FormItem = Form.Item;
const { TabPane } = Tabs;
class SignUp extends React.Component {
  static defaultProps = {
    error: null,
    member: {}
  };
  handleSubmitUser = event => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.signUpUser(values);
      }
    });
  };

  handleSubmitManager = event => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.signUpManager(values);
      }
    });
  };

  signUpUser = values => {
    this.setState({ loading: true });
    this.props
      .signUpUser(values)
      .then(() => {
        this.setState({ loading: false });
        Notify('success', 'Success', 'Created a customer account');
        this.props.push('/signin');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', 'Failed', e);
      });
  };

  signUpManager = values => {
    this.setState({ loading: true });
    this.props
      .signUpManager(values)
      .then(() => {
        this.setState({ loading: false });
        Notify('success', 'Success', 'Created a manager account');
        this.props.push('/signin');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', 'Failed', e);
      });
  };

  toggleShowPass = () => {
    this.state.passShow === 'password'
      ? this.setState({ passShow: 'text' })
      : this.setState({ passShow: 'password' });
  };

  constructor(props) {
    super(props);
    this.state = {
      email: props.member && props.member.email ? props.member.email : '',
      password: '',
      focus: {
        email: false,
        password: false
      },
      loading: false,
      passShow: 'password'
    };
  }

  handleLocaleSwitch = locale => {
    this.setState({ loading: true });

    this.props
      .changeLocale(locale)
      .then(() => {
        this.props.form.resetFields();
        this.setState({ loading: false });
        // location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      // <Section className="panel" {...this.props} >
      <div style={{ background: 'white' }}>
        <Row type="flex" justify="start" align="middle">
          <Col
            style={{ background: '#EC8144', display: 'flex', height: '100vh' }}
            span={10}
          >
            <img
              src={CarPic}
              alt="Smiley face"
              height="100%"
              width="70%"
              style={{
                position: 'absolute',
                left: '0%'
              }}
            />
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              <h1 style={{ color: 'white', fontSize: '55px' }}>
                Welcome Back!
              </h1>
              <p style={{ color: 'white', fontSize: '25px' }}>
                Sign in to continue!
              </p>

              <ButtonRadius
                style={{
                  height: 80,
                  fontSize: 30,
                  width: 300,
                  color: '#EC8144'
                }}
                onClick={() => {
                  this.props.push('/signin');
                }}
                className="submit-button"
                htmlType="submit"
                size="large"
                block
              >
                Sign In
              </ButtonRadius>
            </div>
          </Col>
          <Col offset={5} span={7} pull={2}>
            <h1 style={{ fontSize: '50px', color: '#808285' }}>
              Create a new account
            </h1>
            <Spin spinning={this.state.loading}>
              <Tabs type={'card'}>
                <TabPane tab="I am a customer" key="1">
                  <Form onSubmit={this.handleSubmitUser}>
                    <Row type="flex" align="middle">
                      <Col span={11}>
                        <FormItem
                          label={
                            <span style={{ color: '#00B4BC', fontSize: 20 }}>
                              First Name
                            </span>
                          }
                        >
                          {getFieldDecorator('name', {
                            rules: [
                              {
                                required: true,
                                message: 'Name is required'
                              }
                            ]
                          })(
                            <Input
                              placeholder="Enter first name"
                              style={{
                                width: '100%',
                                border: 0,
                                borderRadius: 0,
                                borderBottom:
                                  '1px solid rgba(189, 178, 178, 0.43)'
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={11}>
                        <FormItem
                          label={
                            <span style={{ color: '#00B4BC', fontSize: 20 }}>
                              Last Name
                            </span>
                          }
                        >
                          {getFieldDecorator('surname', {
                            rules: [
                              {
                                required: true,
                                message: 'Surname is required'
                              }
                            ]
                          })(
                            <Input
                              placeholder="Enter last name"
                              style={{
                                width: '100%',
                                border: 0,
                                borderRadius: 0,
                                borderBottom:
                                  '1px solid rgba(189, 178, 178, 0.43)'
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row type="flex" align="middle">
                      <Col span={11}>
                        <FormItem
                          label={
                            <span style={{ color: '#00B4BC', fontSize: 20 }}>
                              Email Address
                            </span>
                          }
                        >
                          {getFieldDecorator('username', {
                            rules: [
                              {
                                required: true,
                                message: 'E-mail is required'
                              }
                            ]
                          })(
                            <Input
                              placeholder="Enter valid email address"
                              style={{
                                width: '100%',
                                border: 0,
                                borderRadius: 0,
                                borderBottom:
                                  '1px solid rgba(189, 178, 178, 0.43)'
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={11}>
                        <FormItem
                          label={
                            <span style={{ color: '#00B4BC', fontSize: 20 }}>
                              Number
                            </span>
                          }
                        >
                          {getFieldDecorator('number', {
                            rules: [
                              {
                                required: true,
                                message: 'Number is required'
                              }
                            ]
                          })(
                            <Input
                              placeholder="Enter number"
                              style={{
                                width: '100%',
                                border: 0,
                                borderRadius: 0,
                                borderBottom:
                                  '1px solid rgba(189, 178, 178, 0.43)'
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <FormItem
                      label={
                        <span style={{ color: '#00B4BC', fontSize: 20 }}>
                          Password
                        </span>
                      }
                    >
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: 'Password is required'
                          }
                        ]
                      })(
                        <Input
                          placeholder="Enter password"
                          type="password"
                          style={{
                            width: '100%',
                            border: 0,
                            borderRadius: 0,
                            borderBottom: '1px solid rgba(189, 178, 178, 0.43)'
                          }}
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      <ButtonRadius
                        style={{ height: 80, fontSize: 30, marginTop: '1em' }}
                        className="submit-button"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        Sign Up Customer
                      </ButtonRadius>
                    </FormItem>
                  </Form>
                </TabPane>
                <TabPane tab="I am a manager" key="2">
                  <Form onSubmit={this.handleSubmitManager}>
                    <Row type="flex" align="middle">
                      <Col span={11}>
                        <FormItem
                          label={
                            <span style={{ color: '#00B4BC', fontSize: 20 }}>
                              First Name
                            </span>
                          }
                        >
                          {getFieldDecorator('name', {
                            rules: [
                              {
                                required: true,
                                message: 'Name is required'
                              }
                            ]
                          })(
                            <Input
                              placeholder="Enter first name"
                              style={{
                                width: '100%',
                                border: 0,
                                borderRadius: 0,
                                borderBottom:
                                  '1px solid rgba(189, 178, 178, 0.43)'
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={11}>
                        <FormItem
                          label={
                            <span style={{ color: '#00B4BC', fontSize: 20 }}>
                              Last Name
                            </span>
                          }
                        >
                          {getFieldDecorator('surname', {
                            rules: [
                              {
                                required: true,
                                message: 'Surname is required'
                              }
                            ]
                          })(
                            <Input
                              placeholder="Enter last name"
                              style={{
                                width: '100%',
                                border: 0,
                                borderRadius: 0,
                                borderBottom:
                                  '1px solid rgba(189, 178, 178, 0.43)'
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row type="flex" align="middle">
                      <Col span={11}>
                        <FormItem
                          label={
                            <span style={{ color: '#00B4BC', fontSize: 20 }}>
                              Email Address
                            </span>
                          }
                        >
                          {getFieldDecorator('username', {
                            rules: [
                              {
                                required: true,
                                message: 'E-mail is required'
                              }
                            ]
                          })(
                            <Input
                              placeholder="Enter valid email address"
                              style={{
                                width: '100%',
                                border: 0,
                                borderRadius: 0,
                                borderBottom:
                                  '1px solid rgba(189, 178, 178, 0.43)'
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={11}>
                        <FormItem
                          label={
                            <span style={{ color: '#00B4BC', fontSize: 20 }}>
                              Number
                            </span>
                          }
                        >
                          {getFieldDecorator('number', {
                            rules: [
                              {
                                required: true,
                                message: 'Number is required'
                              }
                            ]
                          })(
                            <Input
                              placeholder="Enter number"
                              style={{
                                width: '100%',
                                border: 0,
                                borderRadius: 0,
                                borderBottom:
                                  '1px solid rgba(189, 178, 178, 0.43)'
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <FormItem
                      label={
                        <span style={{ color: '#00B4BC', fontSize: 20 }}>
                          Password
                        </span>
                      }
                    >
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: 'Password is required'
                          }
                        ]
                      })(
                        <Input
                          type="password"
                          style={{
                            width: '100%',
                            border: 0,
                            borderRadius: 0,
                            borderBottom: '1px solid rgba(189, 178, 178, 0.43)'
                          }}
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      <ButtonRadius
                        style={{ height: 80, fontSize: 30, marginTop: '1em' }}
                        className="submit-button"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        Sign Up Manager
                      </ButtonRadius>
                    </FormItem>
                  </Form>
                </TabPane>
              </Tabs>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const WrappedSignUp = Form.create()(SignUp);

export default WrappedSignUp;
