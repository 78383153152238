import { Component } from 'react';
import english from './en';
import afrikaans from './af';
import { connect } from 'react-redux';
import { getLocale } from 'src/redux/selectors';
export const DEFAULT_LOCALE = 'af';

export const Translations = {
  en: english,
  af: afrikaans
};

class I18n extends Component {
  constructor(props) {
    super(props);
    window.i18n = this;
  }
  translate(message, locale = DEFAULT_LOCALE) {
    if (this.props.getLocale) {
      locale = this.props.getLocale;
    }
    // We're actually asking for 'something' to be translated
    if (message) {
      // The translation exists AND the message exists in this translation
      if (Translations[locale] && Translations[locale][message]) {
        return Translations[locale][message];

        // Otherwise try in the default translation
      } else if (
        Translations[DEFAULT_LOCALE] &&
        Translations[DEFAULT_LOCALE][message]
      ) {
        return Translations[DEFAULT_LOCALE][message];
      }
    }

    return '???';
  }
  render = () => {
    return null;
  };
}

const mapStateToProps = state => ({
  getLocale: getLocale(state) || {}
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(I18n);
