export const initialState = {
  currentRequests: [],
  previousRequests: [],
  myRequest: [],
  currentBatchRequests: [],
  previousBatchRequests: [],
  myBatchRequest: [],
  basketRequestIds: [],
  auctionRequest: [],
  auctionRequestIds: [],
  dealDoneRequest: [],
  wonRequest: [],
  lostRequest: [],
  editRequest: undefined,
  requestDetails: undefined,
  requestDetailsId: '',
  customTime: undefined,
  timeStamp: undefined
};

export default function requestReducer(state = initialState, action) {
  switch (action.type) {
    case 'REQUEST_FIND_ALLREQUESTS': {
      if (action.data) {
        const currentRequests = action.data.currentRequests;
        const previousRequests = action.data.previousRequests;

        return {
          ...state,
          currentRequests: currentRequests,
          previousRequests: previousRequests
        };
      }
      return initialState;
    }
    case 'REQUEST_FIND_MYREQUESTS': {
      if (action.data) {
        const myRequest = action.data.myRequest;

        return {
          ...state,
          myRequest: myRequest
        };
      }
      return initialState;
    }
    case 'REQUEST_FIND_ALLBATCHREQUESTS': {
      if (action.data) {
        const currentBatchRequests = action.data.currentBatchRequests;
        const previousBatchRequests = action.data.previousBatchRequests;

        return {
          ...state,
          currentBatchRequests: currentBatchRequests,
          previousBatchRequests: previousBatchRequests
        };
      }
      return initialState;
    }
    case 'REQUEST_FIND_MYBATCHREQUESTS': {
      if (action.data) {
        const myBatchRequest = action.data.myBatchRequest;

        return {
          ...state,
          myBatchRequest: myBatchRequest
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
