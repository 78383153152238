export const initialState = {
  sidebarCollapsed: true,
  rig1: false,
  rig2: false
};

export default function statusReducer(state = initialState, action) {
  switch (action.type) {
    case 'SIDEBAR_COLLAPSE': {
      let partialState = {
        sidebarCollapsed: !state.sidebarCollapsed
      };

      return {
        ...state,
        ...partialState
      };
    }
    case 'UPDATE_RIG1': {
      return {
        ...state,
        rig1: true
      };
    }
    case 'UPDATE_RIG2': {
      return {
        ...state,
        rig1: false,
        rig2: true
      };
    }
    default:
      return state;
  }
}
