import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLocale } from 'src/redux/selectors';

class Dashboard extends Component {
  render = () => {
    const { Layout, currentLocale } = this.props;
    return <Layout currentLocale={currentLocale} />;
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {}
});

const mapDispatchToProps = {
  getLocale
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
