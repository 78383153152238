import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { approvePexRequest, rejectPexRequest } from 'src/redux/actions/admin';
import { sendNotification } from 'src/redux/actions/notifications';
import {
  findAllRequest,
  addRequest,
  deleteRequest,
  findJobNumbers,
  findTechnicians
} from 'src/redux/actions/pexrequest';
import { withRouter, Link } from 'react-router-dom';

class Request extends Component {
  render = () => {
    const {
      Layout,
      currentRequests,
      previousRequests,
      user,
      findAllRequest,
      approvePexRequest,
      rejectPexRequest,
      sendNotification,
      findJobNumbers,
      findTechnicians,
      addRequest,
      deleteRequest,
      push,
      history
    } = this.props;
    return (
      <Layout
        currentRequests={currentRequests}
        previousRequests={previousRequests}
        user={user}
        findAllRequest={findAllRequest}
        approveRequest={approvePexRequest}
        rejectRequest={rejectPexRequest}
        sendNotification={sendNotification}
        findJobNumbers={findJobNumbers}
        findTechnicians={findTechnicians}
        addRequest={addRequest}
        deleteRequest={deleteRequest}
        push={push}
        history={history}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentRequests: state.pexrequest.currentRequests || [],
  previousRequests: state.pexrequest.previousRequests || [],
  user: state.user.user || {}
});

const mapDispatchToProps = {
  findAllRequest,
  approvePexRequest,
  rejectPexRequest,
  sendNotification,
  push,
  findJobNumbers,
  findTechnicians,
  addRequest,
  deleteRequest
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Request)
);
