import { notification } from 'antd';

const Notify = (type, title, message, duration) => {
  notification[type]({
    message: title,
    description: message
      ? message.message
        ? message.message
        : message
      : message,
    duration: duration ? duration : 4.5
  });
};

export default Notify;
