/* global window */
import React from 'react';
import {
  CheckOutlined,
  StockOutlined,
  TeamOutlined,
  DashboardOutlined,
  SettingOutlined,
  PoweroffOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  HomeOutlined,
  CarOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { BiUser } from 'react-icons/bi';
import { BsCash, BsTrophy } from 'react-icons/bs';
import { MdVideoLibrary } from 'react-icons/md';
import { GiAchievement } from 'react-icons/gi';
import { Layout, Menu, Row, Col, Space, Button, Popover } from 'antd';
import LogoSmall from '../../static/logo-small.svg';
import logo from 'src/static/logo-white.svg';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Cookies } from 'react-cookie';
// import { getSidebarCollapsed } from 'src/redux/selectors';
// import { collapseSidebar } from 'src/redux/actions/status';
import { MenuItem } from 'rc-menu';

if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const StyledLink = styled(Link)`
  font-size: 15px;
  color: white !important;
  :hover {
    text-decoration: none !important;
  }
  .anticon svg {
    display: inline-block;
    font-size: 20px !important;
    color: black !important;
  }
`;

const { Sider } = Layout;

const Sidebar = props => {
  const signOut = () => {
    if (window.cordova) {
      window.FirebasePlugin.getToken(
        token => {
          window.pubnub.push.deleteDevice(
            {
              device: token,
              pushGateway: 'gcm' // apns, gcm, mpns
            },
            function (status) {
              if (status.error) {
                console.log('operation failed w/ error:', status);
              }
            }
          );
        },
        error => {
          console.error(error);
        }
      );
    }

    const cookies = new Cookies();
    cookies.remove('token');
    cookies.remove('basic');
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Sider
      collapsedWidth={window.isPhone ? 40 : 99}
      // trigger={false}
      // collapsible
      defaultCollapsed={true}
      style={{
        //height: "calc(100vh - 50px)",
        //marginTop: "50px",
        // border: '1px solid black3b',
        zIndex: 9,
        position: 'fixed',
        background: 'white',
        textAlign: 'center'
        //height: 'calc(100vh - 40px)',
        //left: 0,
        //top: 40
      }}
    >
      <img
        // onClick={props.collapseSidebar}
        src={props.collapsed ? LogoSmall : LogoSmall}
        alt="Logo"
        style={{
          width: props.collapsed ? '50%' : '50%',
          marginTop: 5,
          marginLeft: props.collapsed ? '' : ''
        }}
      />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['stock']}
        style={{ background: 'transparent', marginTop: '3vh' }}
      >
        {/*<Menu.Item
          onClick={props.collapsed ? null : props.collapseSidebar}
          key='dashboard'
          icon={
            <DashboardOutlined style={{ fontSize: "20px", color: "black" }} />
          }
        >
          <StyledLink to='/user/dashboard'>
            <span>Dashboard</span>
          </StyledLink>
        </Menu.Item>*/}

        <Menu.Item
          // onClick={props.collapsed ? null : props.collapseSidebar}
          key="stock"
          icon={
            <MdVideoLibrary
              style={{
                fontSize: window.isPhone ? '' : '25px',
                color: 'lightgrey',
                marginTop: '0.3em'
              }}
            />
          }
        >
          <StyledLink to="/user/stock">
            <span>Events</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item
          // onClick={props.collapsed ? null : props.collapseSidebar}
          key="usermanagement"
          icon={
            <BiUser
              style={{
                fontSize: window.isPhone ? '' : '25px',
                color: 'lightgrey',
                marginTop: '0.3em'
              }}
            />
          }
        >
          <StyledLink to="/user/users">
            <span>Member View</span>
          </StyledLink>
        </Menu.Item>
        {/* <Menu.Item
          // onClick={props.collapsed ? null : props.collapseSidebar}
          key="pexunits"
          icon={
            <BsCash
              style={{
                fontSize: window.isPhone ? '' : '25px',
                color: 'lightgrey',
                marginTop: '0.3em'
              }}
            />
          }
        >
          <StyledLink to="/user/dashboard">
            <span>Account</span>
          </StyledLink>
        </Menu.Item> */}
        {/* <Menu.Item
          // onClick={props.collapsed ? null : props.collapseSidebar}
          key="Achievements"
          icon={
            <GiAchievement
              style={{
                fontSize: window.isPhone ? '' : '25px',
                color: 'lightgrey',
                marginTop: '0.3em'
              }}
            />
          }
        >
          <StyledLink to="/user/requisition">
            <span>Achievements</span>
          </StyledLink>
        </Menu.Item> */}
        {/*<Menu.Item
          icon={
            <SettingOutlined style={{ fontSize: '20px', color: 'black' }} />
          }
          key="settings"
          style={{
            position: 'absolute',
            bottom: 60,
            width: props.collapsed ? 90 : 210
          }}
        >
          <StyledLink to="/user/dashboard">
            <span>Settings</span>
          </StyledLink>
        </Menu.Item>*/}
      </Menu>
    </Sider>
  );
};

const mapStateToProps = state => {
  return {
    // collapsed: getSidebarCollapsed(state)
  };
};

const mapDispatchToProps = {
  // collapseSidebar
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
