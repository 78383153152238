import styled from 'styled-components';
import { Button } from 'antd';

const ButtonRadius = styled(Button)`
  && {
    border-radius: 50px;
    font-weight: bold;
  }
`;

const ButtonSquare = styled(Button)`
  && {
    border-radius: 5px;
  }
`;

export { ButtonRadius, ButtonSquare };
